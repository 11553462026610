import i18n from 'i18next';
import { keys } from './translator/translation_keys';

export const incidentTypes = {
  'Health and Safety': [
    'Fatality',
    'Lost Time',
    'Medical Aid',
    'First Aid',
    'Near Miss',
    'Medical - No Treatment',
    'Other',
  ],
  Environment: [
    'Spill to Land',
    'Spill to Water',
    'Waste Segregation',
    'Near Miss',
    'Other',
  ],
  Commercial: ['Change'],
  'Equipment/Property': ['Maintenance', 'Failure', 'Damage', 'Other'],
  Aviation: [
    'Medivac',
    'Offshore Flight/Crew Change',
    'SAR/SAS',
    'VFR Operations',
    'Test Flight',
    'Training',
    'Ramp Operations',
    'Hangar Operations',
    'Heavy Lift Operations',
    'Helideck Operations',
    'Maintenance',
    'Wx Observations',
    'Other',
  ],
};

export const incidentDetailQuestions = [
  {
    title: keys.incidents.DATE_OF_INCIDENT,
    convertToQuery: 'dateOfIncident',
    inputType: 'DATE_TIME',
    id: 1,
    required: true,
  },
  {
    title: keys.incidents.LOCATION,
    convertToQuery: 'location',
    inputType: 'TEXT',
    id: 3,
    required: true,
  },
  {
    title: keys.incidents.INCIDENT_DESCRIPTION,
    convertToQuery: 'description',
    inputType: 'TEXT_MULTI',
    id: 4,
    required: true,
  },
];

export const aviationPersonnelQuestions = {
  personnel: ['Flight Crew', 'Technical Crew', 'Passengers', 'Other'],
};

export const incidentAviationQuestions = {
  flight: [
    {
      title: 'Flight Number',
      inputType: 'TEXT',
      convertToQuery: 'flightNumber',
    },
    {
      title: 'Gross Weight',
      inputType: 'NUMBER',
      convertToQuery: 'grossWeight',
      items: ['lbs', 'kg'],
      subquery: 'units',
    },
  ],
  destination: [
    {
      title: 'Departure Location',
      inputType: 'TEXT',
      convertToQuery: 'departureLocation',
    },
    {
      title: 'Intended Destination',
      inputType: 'TEXT',
      convertToQuery: 'destination',
    },
  ],
};

export const ATAchapters = [
  { key: '06', label: 'ATA-06 Dimensions and Areas' },
  { key: '07', label: 'ATA-07 Lifting and Shoring' },
  { key: '08', label: 'ATA-08 Leveling and Weighing' },
  { key: '09', label: 'ATA-09 Towing and Taxing' },
  { key: '10', label: 'ATA-10 Parking' },
  { key: '11', label: 'ATA-11 Placards and Markings' },
  { key: '18', label: 'ATA-18 Vibration and Noise Analysis' },
  { key: '20', label: 'ATA-20 Standard Practices' },
  { key: '21', label: 'ATA-21 Air Conditioning' },
  { key: '22', label: 'ATA-22 Auto Flight System' },
  { key: '23', label: 'ATA-23 Communications' },
  { key: '24', label: 'ATA-24 Electrical System' },
  { key: '25', label: 'ATA-25 Equipment and Furnishings' },
  { key: '26', label: 'ATA-26 Protection' },
  { key: '28', label: 'ATA-28 Fuel System' },
  { key: '29', label: 'ATA-29 Hydraulic System' },
  { key: '30', label: 'ATA-30 Ice and Rain Protection' },
  { key: '31', label: 'ATA-31 Indicating/Recording System' },
  { key: '32', label: 'ATA-32 Landing Gear' },
  { key: '33', label: 'ATA-33 Lighting' },
  { key: '34', label: 'ATA-34 Navigation Systems' },
  { key: '44', label: 'ATA-44 Cabin Systems' },
  { key: '45', label: 'ATA-45 Central Maintenance System' },
  { key: '49', label: 'ATA-49 Auxiliary Power Unit (APU)' },
  { key: '50', label: 'ATA-50 Cargo and Accessory Compartments' },
  { key: '51', label: 'ATA-51 Standard Practices and Structures' },
  { key: '52', label: 'ATA-52 Doors' },
  { key: '53', label: 'ATA-53 Fuselage' },
  { key: '54', label: 'ATA-54 Nacelles/Pylons' },
  { key: '55', label: 'ATA-55 Stabilizers' },
  { key: '56', label: 'ATA-56 Windows' },
  { key: '62', label: 'ATA-62 Main Rotor (MR) System' },
  { key: '63', label: 'ATA-63 Main Rotor (MR) Drive System' },
  { key: '64', label: 'ATA-64 Tail Rotor (TR) System' },
  { key: '65', label: 'ATA-64 Tail Rotor (TR) Drive System' },
  { key: '67', label: 'ATA-67 Mechanical Flight Controls' },
  { key: '71', label: 'ATA-71 Powerplant' },
  { key: '76', label: 'ATA-76 Engine Controls' },
  { key: '78', label: 'ATA-78 Engine Exhaust System' },
  { key: '80', label: 'ATA-80 Engine Starting' },
  { key: '00', label: 'Other/Misc' },
];

export const incidentStatusTypes = {
  INITIATED: {
    color: 'red',
    icon: 'play_circle_outline_icon',
    title: 'Initiated',
  },
  INVESTIGATION: {
    color: 'yellow',
    icon: 'person_search',
    title: 'In Progress',
  },
  COMPLETE: {
    color: 'green',
    icon: 'check_circle',
  },
  PENDING: {
    color: 'red',
    icon: 'timer',
    title: 'Initiated',
  },
  CLOSED: {
    color: 'gray',
    icon: 'check_circle',
  },
  INVALID: {
    color: 'graylight',
    icon: 'highlight_off',
  },
};

export const getLabel = (status) => {
  switch (status) {
    case 'INITIATED':
      return `${i18n.t(keys.incidents.INITIATED)}`;
    case 'INVESTIGATION':
      return `${i18n.t(keys.incidents.INVESTIGATION)}`;
    case 'COMPLETE':
      return `${i18n.t(keys.common.COMPLETE)}`;
    case 'PENDING':
      return `${i18n.t(keys.incidents.PENDING)}`;
    case 'CLOSED':
      return `${i18n.t(keys.incidents.CLOSED)}`;
    case 'INVALID':
      return `${i18n.t(keys.incidents.INVALID)}`;
    default:
      return '-';
  }
};

export const aviationJSONQuestions = {
  PILOT: 'pilot',
  NONFLYING_PILOT: 'nonFlyingPilot',
  GROSS_WEIGHT: 'grossWeight',
  FLIGHT_NUMBER: 'flightNumber',
  DEPARTURE_LOCATION: 'departureLocation',
  DESTINATION: 'destination',
  DEPARTURE_DATE: 'departureDate',
  TIMEZONE: 'timezone',
  CATEGORY: 'category',
  TSB: 'tsb',
  CFN: 'cfn',
  DNA: 'dna',
};

export const adminAviationFields = {
  tsb: 'Is this TSB reportable?',
  cfn: 'Customer Flight Notification required?',
  dna: 'Drug and Alcohol Testing required?',
};

export const aviationFields = {
  category: 'Incident Category',
  operation: 'Type of Operation',
  pilot: 'Pilot in Command',
  nonFlyingPilot: 'Second in Command',
  grossWeight: 'Gross Weight',
  departureDate: 'Date and Time of Departure',
  timezone: 'Timezone',
  flightNumber: 'Flight Number',
  departureLocation: 'Departure Location',
  destination: 'Intended Destination',
};

export const aviationFieldsAdditions = {
  ...aviationFields,
  selectedOptions: 'Events',
  cfn: 'Customer Flight Notification',
  tsb: 'TSB Reportable',
  personnel: 'Personnel on Board',
  vehicle: 'Aircraft',
  dna: 'Drug and Alcohol Test',
};
export const aviationOptions = [
  'Turnback',
  'PAN Call',
  'MAYDAY Call',
  'Return to Base',
];
export const yesNoQuestions = ['tsb', 'cfn', 'investigation'];

export const aviationResponseTime = {
  Low: 15,
  Medium: 10,
  High: 4,
};
