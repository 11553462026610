import styles from './CorrectiveAction.module.scss';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';
import Label from '../../components/action_items/Label';
import {
  priorityStringLookup,
  statusStringLookup,
} from '../../components/action_items/action_item_utilities';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';
import ActionItemComment from '../../components/action_items/ActionItemComment';
import ActionItemHistory from '../../components/action_items/ActionItemHistory';
import { Form, Spinner } from 'react-bootstrap';
import { useModal } from '../../providers/ModalProvider';
import { Button } from '../../components';
import ActionItemCreateComment from '../../components/action_items/ActionItemCreateComment';
import QuickFileDropzone from '../../components/image_uploads/QuickFileDropzone';
import Icon from '../../components/Icon';
import { useCurrentUser } from '../../providers/UserProvider';
import { cpaStatusTypes, cpaStringLookup } from '../../utilities/audits';
import TextWithIcon from '../../components/typography/TextWithIcon';
import UserMultiSelector from '../../components/UserMultiSelector';
import { deepCompareObjects } from '../../utilities';
import ExtensionRequests from './ExtensionRequests';
import moment from 'moment';

const actionItemEventQuery = loader('./CorrectiveAction.actionItem.graphql');
const updateActionItemMutation = loader(
  './CorrectiveAction.updateActionItem.graphql',
);
const addActionItemComment = loader('./CorrectiveAction.addComment.graphql');
const addActionItemAttachmentMutation = loader(
  './CorrectiveAction.addAttachment.graphql',
);
const removeParticipantMutation = loader(
  './CorrectiveAction.deleteParticipant.graphql',
);
const addParticipantMutation = loader(
  './CorrectiveAction.addParticipant.graphql',
);
const updateCPAMutation = loader('./CPAPage.update.graphql');

export default function CorrectiveAction({
  actionItemId,
  footer = false,
  allowEditDueDate = false,
  correctiveAction,
  onSubmit = () => {},
  onDeny = () => {},
  allowDelegates = true,
  allowExtension = false,
  showDueDate = true,
  leftFooter = null,
}) {
  const [updateActionItem] = useMutation(updateActionItemMutation);
  const [addComment] = useMutation(addActionItemComment);
  const [addAttachment] = useMutation(addActionItemAttachmentMutation);
  const [updateCPA] = useMutation(updateCPAMutation);
  const [addParticipant] = useMutation(addParticipantMutation);
  const [removeParticipant] = useMutation(removeParticipantMutation);

  const { openConfirmationModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [isEditingDueDate, setIsEditingDueDate] = useState(
    allowEditDueDate ? true : false,
  );
  const { user, isAdmin } = useCurrentUser();
  const [showModal, setShowModal] = useState(false);

  const {
    refetch,
    data: { events = [], actionItems: [actionItem] = [{}] } = {},
  } = useQuery(actionItemEventQuery, {
    skip: !actionItemId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: `${actionItemId}`,
    },
  });

  const activity = useMemo(() => {
    if (!!actionItemId) {
      const activityEvents = events || [];
      const activityComments = actionItem?.comments || [];

      const mappedEvents = activityEvents?.map((event) => ({
        ...event,
        moment: getLocalTime(event.dateCreated),
      }));

      const mappedComments = activityComments?.map((comment) => ({
        ...comment,
        moment: getLocalTime(comment.dateCreated),
      }));

      const mergedArray = [...mappedEvents, ...mappedComments];

      mergedArray.sort((a, b) => a.moment - b.moment);

      return mergedArray;
    }
    return [];
  }, [actionItemId, events, actionItem?.comments]);

  const { t } = useTranslation();
  const readOnly = !!actionItem.locked;
  const [state, setState] = useState({ actionItem });

  useEffect(() => {
    if (!deepCompareObjects(state, actionItem)) {
      setState(actionItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionItem]);

  async function updateActionItemStatus(status) {
    await updateActionItem({
      variables: {
        id: actionItem.id,
        status,
      },
    });
  }
  const disableUpdate = useMemo(() => {
    if (actionItem) {
      const participantIds = actionItem?.participants?.map((p) => p.id) || [];
      const assigneeId = actionItem?.assignee?.id;
      const permissions = [assigneeId, ...participantIds];
      if (permissions.includes(user.id)) {
        return false;
      }
    }
    return true;
  }, [actionItem, user.id]);
  const [showHistory, setShowHistory] = useState(false);
  const isCreator = user.id === correctiveAction?.creator?.id;
  const isAssignee = user.id === correctiveAction?.assignee?.id;

  const pendingRequest = actionItem.extension
    ?.map((e) => e)
    .find((f) => f.status === 'PENDING');

  return !actionItem?.id ? (
    <Spinner animation="border" variant="primary" />
  ) : (
    !!actionItem?.id && (
      <div className={styles.page}>
        <div className={styles.container}>
          <div className={styles.left}>
            {!readOnly && (
              <ActionItemCreateComment
                onSubmit={(text) => {
                  addComment({
                    variables: { actionItemId: actionItem.id, text },
                  }).then(() => {
                    refetch().then(() => {
                      if (!disableUpdate && actionItem.status === 'TODO') {
                        updateActionItemStatus(
                          statusStringLookup.IN_PROGRESS.key,
                        );
                      }
                    });
                  });
                }}
              />
            )}
            <div className={styles.header}>
              <Text size="sm" noMargin color="secondary">
                Activity
              </Text>
              <Text
                hover
                color="accentPrimary"
                weight="semiBold"
                size="sm"
                noMargin
                onClick={() => {
                  setShowHistory(!showHistory);
                }}
              >
                {showHistory ? 'Hide History' : 'Show History'}
              </Text>
            </div>
            <div className={styles.commentList}>
              {activity?.map((event) =>
                event.__typename === 'ActionItemComment' ? (
                  <ActionItemComment
                    key={`${event.__typename}:${event.id}`}
                    comment={event}
                  />
                ) : showHistory ? (
                  <ActionItemHistory
                    key={`${event.__typename}:${event.id}`}
                    event={event}
                    isCorrective={true}
                  />
                ) : null,
              )}
            </div>
            <Text size="sm" noMargin color="secondary">
              Action Taken
            </Text>
            {readOnly || disableUpdate ? (
              <Text weight="semiBold" noMargin>
                {actionItem?.outcome}
              </Text>
            ) : (
              <Form.Control
                as="textarea"
                value={state.outcome || undefined}
                rows={5}
                onChange={(e) => {
                  setState({ ...state, outcome: e.target.value });
                }}
                onBlur={(e) => {
                  updateActionItem({
                    variables: {
                      id: actionItem.id,
                      outcome: e.target.value,
                    },
                  }).then(() => {
                    if (actionItem.status === 'TODO') {
                      updateActionItemStatus(
                        statusStringLookup.IN_PROGRESS.key,
                      );
                    }
                  });
                }}
              />
            )}
            <Text size="sm" noMargin color="secondary">
              Attachments
            </Text>
            {readOnly && !actionItem.attachments.length && (
              <Text noMargin color="secondary">
                None
              </Text>
            )}
            <QuickFileDropzone
              setLoading={setLoading}
              loading={loading}
              disableAddFile={readOnly}
              icon={actionItem.attachments?.length > 0}
              readOnly={readOnly}
              attachments={actionItem.attachments ?? []}
              onSubmit={({ url, name, extension }) =>
                addAttachment({
                  variables: {
                    actionItemId: actionItem.id,
                    url,
                    extension,
                    name,
                  },
                }).then(() => {
                  if (actionItem.status === 'TODO' && !disableUpdate) {
                    updateActionItemStatus(statusStringLookup.IN_PROGRESS.key);
                  }
                })
              }
            />
          </div>
          <div className={styles.right}>
            <Text size="sm" noMargin color="secondary">
              Status
            </Text>
            <Label
              rowSized={false}
              name={t(statusStringLookup[actionItem.status].title)}
              color={statusStringLookup[actionItem.status].color}
            />
            <Text size="sm" noMargin color="secondary">
              Priority
            </Text>
            <Text
              weight="bold"
              color={
                priorityStringLookup[actionItem.priority]?.color || 'primary'
              }
              noMargin
            >
              {actionItem.priority}
            </Text>
            {showDueDate && (
              <>
                <Text size="sm" noMargin color="secondary">
                  Due Date
                </Text>
                {!isEditingDueDate || actionItem.locked ? (
                  <div className={styles.flexRow}>
                    <Text weight="semiBold" noMargin>
                      {actionItem.dueDate
                        ? getLocalTime(actionItem?.dueDate).format(
                            'ddd, MMMM Do YYYY',
                          )
                        : 'None'}
                    </Text>
                    {allowEditDueDate && !readOnly ? (
                      <Icon
                        hover
                        style={{ fontSize: '1.2rem' }}
                        onClick={(e) => {
                          updateActionItem({
                            variables: {
                              id: actionItem.id,
                              dueDate: e.target.value,
                            },
                          }).then(() => {
                            if (actionItem.status === 'TODO') {
                              updateActionItemStatus(
                                statusStringLookup.IN_PROGRESS.key,
                              );
                            }
                            setIsEditingDueDate(true);
                          });
                        }}
                      >
                        edit
                      </Icon>
                    ) : null}
                  </div>
                ) : (
                  <Form.Control
                    type="date"
                    className={styles.empty}
                    defaultValue={state?.dueDate || undefined}
                    placeholder="Select Due Date"
                    onChange={(e) => {
                      const dueDate = new moment(e.target.value).format(
                        'YYYY-MM-DD 23:59:59',
                      );
                      updateActionItem({
                        variables: {
                          id: actionItem.id,
                          dueDate,
                        },
                      }).then(() => {
                        if (actionItem.status === 'TODO' && !disableUpdate) {
                          updateActionItemStatus(
                            statusStringLookup.IN_PROGRESS.key,
                          );
                        }
                        setIsEditingDueDate(false);
                      });
                    }}
                    min={new Date().toISOString().split('T')[0]}
                  />
                )}

                {allowDelegates && (
                  <>
                    <Text noMargin size="sm" color="secondary">
                      Delegates
                    </Text>
                    <UserMultiSelector
                      type="all"
                      readOnly={user.id !== actionItem.assignee?.id || readOnly}
                      selected={state?.participants}
                      onUserAdded={(user) =>
                        addParticipant({
                          variables: {
                            actionItemId: actionItem.id,
                            userId: user?.id,
                          },
                        })
                      }
                      onUserRemoved={(user) =>
                        removeParticipant({
                          variables: {
                            actionItemId: actionItem?.id,
                            userId: user.id,
                          },
                        })
                      }
                      usersToLeaveOut={state?.assignee ? [state?.assignee] : []}
                    />
                  </>
                )}
              </>
            )}
            {allowExtension ? (
              <ExtensionRequests
                extensions={actionItem.extension}
                showModal={showModal}
                setShowModal={setShowModal}
                isAssignee={isAssignee}
                isCreator={isCreator}
                actionItem={actionItem}
                pendingRequest={pendingRequest}
              />
            ) : null}
          </div>
        </div>
        <div className={styles.footer}>
          <div>{leftFooter}</div>
          <div className={styles.buttons}>
            {footer && !readOnly && (
              <Button
                value="Unacceptable"
                icon={'close'}
                disabled={!actionItem?.outcome || !isAdmin}
                variant="danger"
                onClick={() =>
                  openConfirmationModal({
                    variant: 'danger',
                    title: 'Unacceptable Corrective Action Plan',
                    description:
                      'Are you sure you want to mark this as unacceptable? Corrective actions cannot be edited once submitted and this action cannot be undone.',
                    onSubmit: () => {
                      updateCPA({
                        variables: {
                          id: correctiveAction.id,
                          status: cpaStatusTypes.DENIED.key,
                        },
                      }).then(() => {
                        updateActionItem({
                          variables: {
                            id: actionItem.id,
                            locked: true,
                          },
                        }).then(() => {
                          updateActionItemStatus(
                            statusStringLookup.DONE.key,
                          ).then(() => {
                            onDeny();
                          });
                        });
                      });
                    },
                  })
                }
              />
            )}
            {!readOnly ? (
              <div className={styles.buttons}>
                {allowExtension &&
                  (isAssignee ? (
                    <Button
                      value={!!pendingRequest ? 'Pending' : 'Extension'}
                      icon="timer"
                      disabled={!!pendingRequest}
                      variant="warning"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    />
                  ) : null)}
                <Button
                  hover
                  disabled={!actionItem.outcome || disableUpdate}
                  className={styles.button}
                  icon="check"
                  variant="success"
                  value="Complete"
                  noMargin
                  color="accentPrimary"
                  onClick={() => {
                    openConfirmationModal({
                      variant: 'warning',
                      title: `Submit ${cpaStringLookup[actionItem.type]}`,
                      description: `Are you sure you want to submit your ${
                        cpaStringLookup[actionItem.type]
                      }? You will not be able to edit after submission, this action cannot be undone. `,
                      onSubmit: () => {
                        updateActionItem({
                          variables: {
                            id: actionItem.id,
                            locked: true,
                            status: statusStringLookup.DONE.key,
                          },
                        }).then(
                          updateActionItemStatus(
                            statusStringLookup.DONE.key,
                          ).then(() => onSubmit()),
                        );
                      },
                    });
                  }}
                />
              </div>
            ) : (
              <TextWithIcon
                icon="locked"
                size="sm"
                noMargin
              >{`Completed on ${getLocalTime(actionItem.dateModified).format(
                'dddd, MMMM DD YYYY HH:mm',
              )}`}</TextWithIcon>
            )}
          </div>
        </div>
      </div>
    )
  );
}
