import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import {
  PageHeader,
  PageFooter,
  styles,
  PageHeaderLogo,
  ActionItems,
} from '../constants';
import { Notes } from '../constants';
import { useTranslation } from 'react-i18next';
import { keys } from '../../translator/translation_keys';
import { VehicleCard } from '../vehicle';
import { Category } from './assessment_category';
import { Signatures } from './assessment_signatures';

export const AssessmentDocument = ({
  inspection,
  workspace,
  actionItems,
  notes,
}) => {
  const { t } = useTranslation();
  const company = inspection?.creator?.company;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageHeaderLogo company={company} workspace={workspace} />
        <PageHeader
          company={company}
          data={inspection}
          title={inspection.template.title}
          workspace={workspace}
          type="ASSESSMENT"
        />
        <View>
          <Text style={styles.titleHeader}>{inspection.template.title}</Text>
          <Text style={styles.headerText}>
            {inspection.template.description}
          </Text>
        </View>
        {!!inspection.vehicle ? (
          <VehicleCard vehicle={inspection?.vehicle} />
        ) : null}
        <View style={styles.categoryContainer}>
          {inspection.template.categories.map((category, index) => (
            <Category
              key={`${category.id}-${index}`}
              category={category.title}
              categoryId={category.id}
              isBundle={category.isBundle}
              questions={category.questions}
              answers={inspection.items}
            />
          ))}
        </View>
        <Notes notes={notes} style={styles.categoryContainer} />
        <Text style={styles.text}>{inspection.notes}</Text>
        {!!inspection.template?.images?.length && (
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryHeader}>
              {t(keys.common.REFERENCE_IMAGES)}
            </Text>
            <View style={styles.templateImageContainer}>
              {inspection.template.images.map(({ imageUrl, index }) => (
                <Image
                  style={styles.templateImage}
                  src={imageUrl}
                  key={`${imageUrl.id}-${index}`}
                />
              ))}
            </View>
          </View>
        )}
        <ActionItems
          style={styles.categoryContainer}
          actionItems={actionItems || []}
        />
        <Signatures
          signatures={
            inspection.attachments?.filter(
              (a) => a.extension === 'SIGNATURE',
            ) || []
          }
        />
        <PageFooter />
      </Page>
    </Document>
  );
};
