import styles from './IncidentReport.module.scss';
import classNames from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { defaultNavbarState, navbarVar } from '../../graphql/cache/navbar';
import { Text } from '../../components/typography';
import { Icon } from '@mui/material';
import {
  aviationResponseTime,
  incidentDetailQuestions,
} from '../../utilities/incidents';
import Accordian from '../../components/Accordian';
import { Button } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  assessmentTypes,
  incidentStatus,
  paths,
} from '../../constants/strings';
import { showToast, signatureModalVar } from '../../graphql/cache/modal';
import { toastVariant, toastLength } from '../../constants/misc';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import IncidentSidebar from './IncidentSidebar';
import { Table } from 'react-bootstrap';
import SimpleUserCard from '../../components/SimpleUserCard';
import { getLocalTime } from '../../utilities/time';
import Label from '../../components/action_items/Label';
import { Form } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import FormQuestion from '../../components/forms/FormQuestion';
import { useActionItemModal, useIsMobile } from '../../hooks/misc';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { getRoute } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import { incidentTypes } from '../../utilities/incidents';
import IncidentImageCard from './IncidentImageCard';
import SimpleFileCard from '../../components/files/SimpleFileCard';
import { imageExtensions } from '../../utilities/files';
import moment from 'moment';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { useIncidents } from '../../hooks/offline-hooks/incidentHook';
import { calculateRisk } from '../../utilities/riskMatrix';
import { addDays } from 'date-fns';
import { IncidentReportAviation } from './IncidentReportAviation';
import { statusStringLookup } from '../../components/action_items/action_item_utilities';
import Notes from '../../components/notes/Notes';
import IncidentReportAdmin from './IncidentReportAdmin';
import IncidentRequiredComments from './IncidentRequiredComments';

const incidentQuery = loader('./IncidentReport.fetch.graphql');
const publishMutation = loader('./IncidentReport.publish.graphql');
const addIncidentImageMutation = loader('./IncidentReport.addImage.graphql');
const incidentInspectionQuery = loader('./IncidentReport.inspections.graphql');
const actionItemQuery = loader('./IncidentReport.actionItems.graphql');
const addActionItemMutation = loader('./IncidentReport.addActionItem.graphql');
const incidentImageQuery = loader('./IncidentReport.getImages.graphql');
const deleteIncidentImageMutation = loader(
  './IncidentReport.deleteImage.graphql',
);
const reviewerIncidentMutation = loader('./IncidentReport.review.graphql');
const deleteReviewerMutation = loader(
  './IncidentReport.deleteReviewer.graphql',
);
const approvedStatuses = [
  incidentStatus.investigation,
  incidentStatus.complete,
];

const notesStatuses = [
  incidentStatus.complete,
  incidentStatus.invalid,
  incidentStatus.closed,
  incidentStatus.investigation,
];

export default function IncidentReport() {
  const { id } = useParams();
  const { workspace, workspaceId, observationTemplate } = useWorkspace();
  const navigate = useNavigate();
  const { openActionItemModal } = useActionItemModal();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [publishIncident] = useMutation(publishMutation);
  const [addIncidentImage] = useMutation(addIncidentImageMutation);
  const [addActionItem] = useMutation(addActionItemMutation);
  const { updateIncident } = useIncidents();
  const [reviewIncident] = useMutation(reviewerIncidentMutation);
  const [deleteReviewer] = useMutation(deleteReviewerMutation);
  const maxLabels = 3;
  const { user, isAdmin } = useCurrentUser();
  const { openModal, openConfirmationModal } = useModal();
  const { t } = useTranslation();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const incidentId = parseInt(id);
  const [deleteIncidentImage] = useMutation(deleteIncidentImageMutation);
  const isOnline = useOnlineStatus();
  const [loadingImages, setLoadingImages] = useState(false);
  const [lostTimeHrs, setLostTimeHrs] = useState(null);
  const [downTimeHrs, setDownTimeHrs] = useState(null);
  const [notes, setNotes] = useState({});
  useEffect(() => {
    navbarVar({ ...defaultNavbarState, showMenu: false });
  }, []);

  const {
    loading: loadingIncident,
    refetch: refetchIncident,
    data: { incidents: [incident] = [{}] } = {},
  } = useQuery(incidentQuery, {
    skip: !id,
    fetchPolicy: isOnline ? 'cache-and-network' : 'cache-only',
    variables: {
      id: `${id}`,
    },
  });

  const { loading: loadingInspections, data: { inspections } = {} } = useQuery(
    incidentInspectionQuery,
    {
      skip: !id,
      fetchPolicy: 'cache-and-network',
      variables: {
        options: {
          filters: [
            {
              field: 'incidentId',
              operator: 'eq',
              value: [`${id}`],
            },
          ],
        },
      },
    },
  );
  const {
    loading,
    refetch: refetchImages,
    data: { incidentImages } = {},
  } = useQuery(incidentImageQuery, {
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      incidentId: `${id}`,
    },
  });

  const {
    loading: loadingActionItems,
    refetch: refetchActionItems,
    data: incidentActionItems,
  } = useQuery(actionItemQuery, {
    skip: !id,
    variables: {
      incidentId: `${id}`,
      actionItemType: 'INCIDENT',
    },
  });
  const [title, setTitle] = useState(incident?.title);

  useEffect(() => {
    setTitle(incident?.title);
  }, [incident.title]);

  const submitDisabled = useMemo(() => {
    return (
      !incident.description ||
      !incident.location ||
      !incident.dateOfIncident ||
      !incident.title
    );
  }, [
    incident.dateOfIncident,
    incident.description,
    incident.location,
    incident.title,
  ]);

  const closeDisabled = useMemo(() => {
    return (
      !!inspections?.map((i) => i.isDraft)?.includes(true) ||
      !!incident.reviewers
        ?.map((r) => r.status === incidentStatus.commentRequired)
        .includes(true) ||
      !isAdmin
    );
  }, [incident.reviewers, inspections, isAdmin]);

  const isMobile = useIsMobile();

  const { observation } = incident || {};

  const combinedImages = [
    ...(observation?.images || []),
    ...(incidentImages || []),
  ];

  const isImageFile = (extension) => {
    return imageExtensions.includes(extension);
  };

  const allFiles = combinedImages.filter((item) => {
    const extension = item.imageUrl.split('.').pop().toLowerCase();
    return !isImageFile(extension);
  });

  const allImages = combinedImages.filter((item) => {
    const extension = item.imageUrl.split('.').pop().toLowerCase();
    return isImageFile(extension);
  });

  const risk = useMemo(() => {
    let value = null;
    let string = null;
    let dueDate = null;

    if (!isNaN(incident?.impact) && !isNaN(incident?.probability)) {
      value = incident.impact * incident.probability;
      string = calculateRisk(value)?.level;
    }

    if (!!incident.dateOfIncident && !!incident.timeOfDay && !!string) {
      const days = aviationResponseTime[string];
      const dateOfIncident = new Date(incident.dateOfIncident);
      dueDate = addDays(dateOfIncident, days);
    }
    return { value, string, dueDate };
  }, [
    incident.dateOfIncident,
    incident.impact,
    incident.probability,
    incident.timeOfDay,
  ]);
  const readOnly = incident.status !== incidentStatus.initiated || !isAdmin;

  const [isPrivate, setIsPrivate] = useState(incident.private);

  useEffect(() => {
    if (incident) {
      setIsPrivate(incident.private);
    }
  }, [incident]);

  const [selectedUsers, setSelectedUsers] = useState(
    incident?.reviewers
      ?.filter(
        ({ status }) =>
          status === incidentStatus.commentRequired ||
          status === incidentStatus.commentSubmitted,
      )
      .filter(Boolean)
      .map(({ user }) => user) ?? [],
  );

  const requiredComments = incident.reviewers?.filter(
    (r) =>
      r.status === incidentStatus.commentRequired ||
      r.status === incidentStatus.commentSubmitted,
  );

  const requiredQuestionStatus = useMemo(() => {
    const total = requiredComments?.length || 0;
    const complete = requiredComments?.filter((r) => !!r.notes).length;
    return !!total ? `${complete}/${total}` : 'None';
  }, [requiredComments]);

  return (
    <div className={styles.split}>
      <div
        className={classNames([
          styles.container,
          sidebarOpen && isMobile && styles.hide,
        ])}
      >
        {!!incident?.id && (
          <div>
            <div className={styles.header}>
              <div>
                <Text size="lg" weight="bold">
                  {user.company?.incidentName ||
                    t(keys.incidents.INCIDENT_REPORT)}
                </Text>
                <div
                  onClick={() => {
                    setSidebarOpen(true);
                  }}
                  className={classNames([styles.hideDesktop])}
                >
                  <Icon baseClassName="material-icons-outlined">
                    more_horiz
                  </Icon>
                </div>
              </div>
              {isAdmin && incident.status !== 'INITIATED' ? (
                <div>
                  <div className={styles.private}>
                    <Form.Check
                      checked={isPrivate}
                      onChange={() => {
                        const description = `Are you sure you want to make this incident ${
                          isPrivate ? 'public' : 'private'
                        }? This will ${
                          !isPrivate
                            ? 'allow only admins and participants '
                            : 'allow all users '
                        }to view this incident.`;
                        openConfirmationModal({
                          title: 'Change Incident Visibility',
                          description,
                          variant: 'danger',
                          onSubmit: () => {
                            updateIncident({
                              variables: {
                                id: incident.id,
                                private: !isPrivate,
                              },
                            });
                            setIsPrivate(!isPrivate);
                          },
                        });
                      }}
                    />
                    <Text noMargin weight="semiBold">
                      {t(keys.action_items.PRIVATE_TITLE)}
                    </Text>
                  </div>
                  <Text size="sm" color="secondary" textAlign="right" noMargin>
                    {!!incident.private
                      ? t(keys.action_items.PRIVATE_TEXT)
                      : t(keys.action_items.PUBLIC)}
                  </Text>
                </div>
              ) : null}
            </div>
            <div className={styles.titleContainer}>
              {isEditingTitle ? (
                <div style={{ width: '100%' }}>
                  <Form.Control
                    value={title}
                    placeholder={t(keys.action.ENTER, {
                      variable: t(keys.common.TITLE),
                    })}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={() =>
                      updateIncident({
                        variables: { id: incidentId, title },
                      }).then(() => refetchIncident())
                    }
                  />
                  <div className={styles.row}>
                    <DropdownText
                      required={true}
                      highlight
                      placeholder={t(keys.incidents.TYPE)}
                      selected={incident.type}
                      onChange={(newType) =>
                        updateIncident({
                          variables: {
                            id: incidentId,
                            type: newType,
                            subtype: '',
                          },
                        }).then(() => refetchIncident())
                      }
                      items={Object.keys(incidentTypes)}
                    />
                    <DropdownText
                      highlight
                      placeholder={t(keys.incidents.SUBTYPE)}
                      required={true}
                      selected={incident.subtype}
                      onChange={(newType) =>
                        updateIncident({
                          variables: { id: incidentId, subtype: newType },
                        }).then(() => refetchIncident())
                      }
                      items={incidentTypes[incident.type]}
                      disabled={!incident.type}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <Text
                    noMargin
                    weight="bold"
                    size="lg"
                    color={!!incident.title ? 'primary' : 'red'}
                  >
                    {incident.title || 'No Title*'}
                  </Text>
                  <Text noMargin weight="bold" size="md" color="secondary">
                    {`${
                      t(getTranslationKey(incident?.type, 'incidents')) ||
                      incident?.type
                    } - ${
                      t(getTranslationKey(incident?.subtype, 'incidents')) ||
                      incident?.subtype
                    }`}
                  </Text>
                </div>
              )}
              {!readOnly ? (
                <Icon
                  onClick={() => setIsEditingTitle(!isEditingTitle)}
                  baseClassName="material-icons-outlined"
                  style={{ opacity: 0.5, cursor: 'pointer' }}
                >
                  {isEditingTitle ? 'save' : 'edit'}
                </Icon>
              ) : null}
            </div>
            <div className={styles.spacer} />
            <div className={styles.bottomContainer}>
              {!!incident?.id ? (
                <Accordian
                  icon="summarize"
                  button={true}
                  title={t(keys.common.INFORMATION)}
                  message={
                    incident?.status === 'INITIATED'
                      ? t(keys.common.IN_PROGRESS)
                      : t(keys.common.COMPLETE)
                  }
                >
                  <div className={styles.content}>
                    {incidentDetailQuestions.map((question) => (
                      <FormQuestion
                        key={question.id}
                        question={question}
                        objectKey={question.convertToQuery}
                        value={incident[question.convertToQuery]}
                        update={updateIncident}
                        parentId={incident.id}
                        readOnly={readOnly}
                        legacyInput={question.legacyInput}
                      />
                    ))}
                  </div>
                </Accordian>
              ) : (
                <div className={styles.spinnerContainer}>
                  <Spinner
                    animation="border"
                    variant="primary"
                    className={styles.spinner}
                  />
                </div>
              )}
              {incident.type === 'Aviation' ? (
                <>
                  <Accordian title="Aviation Details" icon="flight">
                    <IncidentReportAviation
                      incident={incident}
                      readOnly={readOnly}
                    />
                  </Accordian>
                  {incident.status !== incidentStatus.initiated && (
                    <Accordian
                      title="Administrator Details"
                      icon="local_police"
                    >
                      <IncidentReportAdmin incident={incident} />
                    </Accordian>
                  )}
                </>
              ) : null}
              <Accordian
                icon="image"
                title={`${t(keys.common.IMAGES)}/${t(keys.common.FILES)}`}
                message={combinedImages?.length || 0}
                button={true}
                onClick={
                  incident?.status !== 'COMPLETE'
                    ? () => {
                        openModal({
                          modalName: modals.fileUpload,
                          variables: {
                            simple: true,
                            onLoading: () => setLoadingImages(true),
                            onSubmit: ({ url, name }) => {
                              setLoadingImages(true);
                              addIncidentImage({
                                variables: {
                                  incidentId: incident.id,
                                  description: name,
                                  type: incident.status,
                                  imageUrl: url,
                                },
                              }).then(() => {
                                refetchImages();
                                setLoadingImages(false);
                              });
                            },
                          },
                        });
                      }
                    : false
                }
              >
                <div className={styles.content}>
                  {loadingImages || loading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : !!combinedImages?.length ? (
                    <div>
                      <div className={styles.outerImagesContainer}>
                        {allFiles?.map((f, index) => {
                          const extension = f.imageUrl
                            .split('.')
                            .pop()
                            .toLowerCase();
                          const file = {
                            ...f,
                            fileType: extension,
                            url: f.imageUrl,
                            downloadAllowed: true,
                            creator: !!f.creator
                              ? f.creator
                              : observation?.creator,
                          };
                          return (
                            <SimpleFileCard
                              key={f.id}
                              extension={extension}
                              file={file}
                              readOnly={
                                f.__typename === 'ObservationImage' ||
                                incident.status === incidentStatus.complete
                              }
                              fileName={
                                file.description ??
                                `${extension.toUpperCase()}-observation-attachment-${index}`
                              }
                              onDelete={() => {
                                openConfirmationModal({
                                  title: t(keys.action.DELETE_VARIABLE, {
                                    variable: t(keys.common.IMAGE),
                                  }),
                                  description: t(
                                    keys.action.CONFIRMATION_MESSAGE,
                                    {
                                      variable: t(keys.action.DELETE_VARIABLE, {
                                        variable: t(keys.common.IMAGE),
                                      }),
                                    },
                                  ),
                                  variant: 'danger',
                                  onSubmit: () => {
                                    deleteIncidentImage({
                                      variables: { id: file.id },
                                    }).then(() => refetchImages());
                                  },
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                      <div className={styles.outerImagesContainer}>
                        {allImages?.map((image, index) => {
                          const extension = image.imageUrl
                            .split('.')
                            .pop()
                            .toLowerCase();
                          const file = {
                            ...image,
                            fileType: extension,
                            url: image.imageUrl,
                            creator: !!image.creator
                              ? image.creator
                              : observation?.creator,
                          };
                          return (
                            <IncidentImageCard
                              file={file}
                              key={`${image}-${index}`}
                              refetch={refetchImages}
                              readOnly={
                                image.__typename === 'ObservationImage' ||
                                incident.status === incidentStatus.complete
                              }
                              onDelete={() => {
                                openConfirmationModal({
                                  title: t(keys.action.DELETE_VARIABLE, {
                                    variable: t(keys.common.IMAGE),
                                  }),
                                  description: t(
                                    keys.action.CONFIRMATION_MESSAGE,
                                    {
                                      variable: t(keys.action.DELETE_VARIABLE, {
                                        variable: t(keys.common.IMAGE),
                                      }),
                                    },
                                  ),
                                  variant: 'danger',
                                  onSubmit: () => {
                                    deleteIncidentImage({
                                      variables: { id: file.id },
                                    }).then(() => refetchImages());
                                  },
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Accordian>
              <Accordian
                title="Stakeholder Comments"
                icon="comment"
                message={requiredQuestionStatus}
                button={true}
              >
                <IncidentRequiredComments
                  incident={incident}
                  onUserAdded={(user) => {
                    setSelectedUsers([...selectedUsers, user]);
                    reviewIncident({
                      variables: {
                        status: incidentStatus.commentRequired,
                        userId: user?.id,
                        incidentId,
                      },
                    }).then(() => refetchIncident());
                  }}
                  onUserRemoved={(user) => {
                    const reviewer = incident.reviewers.find(
                      (reviewer) => reviewer.user.id === user.id,
                    );
                    deleteReviewer({
                      variables: {
                        reviewerId: reviewer.id,
                      },
                    });
                    setSelectedUsers(
                      selectedUsers.filter((u) => u.id !== user.id),
                    );
                  }}
                  selectedUsers={selectedUsers}
                  readOnly={incident.status !== incidentStatus.initiated}
                  requiredComments={requiredComments}
                />
              </Accordian>

              {approvedStatuses.includes(incident.status) &&
                !loadingIncident && (
                  <>
                    <Accordian
                      button={true}
                      icon="content_paste"
                      title={t(keys.incidents.INVESTIGATION_FORMS)}
                      message={inspections?.length}
                      onClick={
                        incident.status === incidentStatus.complete
                          ? false
                          : () => {
                              openModal({
                                modalName: modals.createAssessment,
                                variables: {
                                  types: [assessmentTypes.incident],
                                  title: t(
                                    keys.incidents.START_INCIDENT_ASSESSMENT,
                                  ),
                                  incidentId: incident.id,
                                  type: assessmentTypes.incident,
                                  participants: [user],
                                },
                              });
                            }
                      }
                    >
                      <div className={styles.content}>
                        {inspections?.length ? (
                          <div className={styles.inspectionList}>
                            <br />
                            <Table striped bordered hover>
                              <thead style={{ zIndex: 1 }}>
                                <tr>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.TITLE)}
                                    </Text>
                                  </th>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.CREATOR)}
                                    </Text>
                                  </th>
                                  <th className={styles.hideOnMobile}>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.LABELS)}
                                    </Text>
                                  </th>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.DATE_CREATED)}
                                    </Text>
                                  </th>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.STATUS)}
                                    </Text>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {inspections?.map((inspection) => (
                                  <tr
                                    key={inspection.id}
                                    onClick={() =>
                                      navigate(
                                        getRoute(
                                          workspace?.id,
                                          paths.assessment,
                                          inspection?.id,
                                        ),
                                      )
                                    }
                                  >
                                    <td>
                                      <Text size="sm" noMargin noSelect>
                                        {inspection?.template?.title}
                                      </Text>
                                    </td>
                                    <td>
                                      <div className={styles.creator}>
                                        <SimpleUserCard
                                          size="sm"
                                          user={inspection?.creator}
                                          hideAvatar
                                        />
                                      </div>
                                    </td>
                                    <td className={styles.hideOnMobile}>
                                      <div className={styles.labelContainer}>
                                        {inspection?.labels
                                          .slice(0, maxLabels)
                                          .map((label) => (
                                            <Label
                                              key={label.id}
                                              className={styles.label}
                                              name={label.name}
                                              color={label.color.toLowerCase()}
                                            />
                                          ))}
                                        {inspection.labels?.length >
                                          maxLabels && (
                                          <Text
                                            size="sm"
                                            weight="semiBold"
                                            noMargin
                                            color="accentPrimary"
                                          >
                                            {`+ ${
                                              inspection.labels.length -
                                              maxLabels
                                            }`}
                                          </Text>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <Text
                                        size="xs"
                                        noMargin
                                        noVerticalMargin
                                        noSelect
                                      >
                                        {getLocalTime(
                                          inspection?.dateCreated,
                                        ).format('MM/DD/YY')}
                                      </Text>
                                    </td>
                                    <td className={styles.labelContainer}>
                                      {inspection?.isDraft ? (
                                        <Label
                                          className={styles.label}
                                          color="yellow"
                                          name={t(keys.common.IN_PROGRESS)}
                                        />
                                      ) : (
                                        <Label
                                          className={styles.label}
                                          color="green"
                                          name={t(keys.common.COMPLETE)}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className={styles.emptyStateView}>
                            {!loadingInspections ? null : (
                              <div className={styles.spinnerContainer}>
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  className={styles.spinner}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Accordian>
                    <Accordian
                      icon="task_alt"
                      title={t(keys.common.ACTION_ITEMS)}
                      message={incidentActionItems?.actionItems?.length}
                      button={true}
                      onClick={
                        incident.status === incidentStatus.complete
                          ? false
                          : () => {
                              addActionItem({
                                variables: {
                                  title: incident.title,
                                  description: '',
                                  type: 'INCIDENT',
                                  incidentId: incident.id,
                                  workspaceId: workspace.id,
                                },
                              }).then(
                                ({
                                  data: {
                                    addActionItem: { id },
                                  },
                                }) => {
                                  openActionItemModal(
                                    id,
                                    'INCIDENT',
                                    refetchActionItems,
                                  );
                                },
                              );
                            }
                      }
                    >
                      <div className={styles.content}>
                        {incidentActionItems?.actionItems?.length ? (
                          <div className={styles.actionItemsContainer}>
                            <br />
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.TITLE)}
                                    </Text>
                                  </th>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.ASSIGNEE)}
                                    </Text>
                                  </th>
                                  <th
                                    className={classNames([
                                      styles.hideOnMobile,
                                      styles.labelsColumn,
                                    ])}
                                  >
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.LABELS)}
                                    </Text>
                                  </th>
                                  <th
                                    className={classNames([
                                      styles.hideOnMobile,
                                      styles.creatorColumn,
                                    ])}
                                  >
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.CREATOR)}
                                    </Text>
                                  </th>
                                  <th className={styles.statusColumn}>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.STATUS)}
                                    </Text>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {incidentActionItems?.actionItems.map(
                                  (actionItem) => (
                                    <tr
                                      key={actionItem.id}
                                      onClick={() =>
                                        openActionItemModal(
                                          actionItem.id,
                                          refetchActionItems,
                                        )
                                      }
                                    >
                                      <td>
                                        <Text size="sm" noMargin>
                                          {actionItem.title}
                                        </Text>
                                      </td>
                                      <td>
                                        <Text size="sm" noMargin>
                                          {actionItem?.assignee
                                            ? `${actionItem?.assignee?.firstName} ${actionItem?.assignee?.lastName}`
                                            : t(keys.common.NONE)}
                                        </Text>
                                      </td>
                                      <td className={styles.hideOnMobile}>
                                        <div className={styles.labelContainer}>
                                          {actionItem?.labels
                                            .slice(0, maxLabels)
                                            .map((label) => (
                                              <Label
                                                key={label.id}
                                                className={styles.label}
                                                name={label.name}
                                                color={label.color.toLowerCase()}
                                              />
                                            ))}
                                          {actionItem.labels?.length >
                                            maxLabels && (
                                            <Text
                                              size="sm"
                                              weight="semiBold"
                                              noMargin
                                              color="accentPrimary"
                                            >{`+ ${
                                              actionItem.labels.length -
                                              maxLabels
                                            }`}</Text>
                                          )}
                                        </div>
                                      </td>
                                      <td className={styles.hideOnMobile}>
                                        <SimpleUserCard
                                          size="sm"
                                          user={actionItem.creator}
                                        />
                                      </td>
                                      <td>
                                        <Label
                                          name={t(
                                            statusStringLookup[
                                              actionItem.status
                                            ].title,
                                          )}
                                          color={
                                            statusStringLookup[
                                              actionItem.status
                                            ].color
                                          }
                                        ></Label>
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className={styles.emptyStateView}>
                            {!loadingActionItems ? null : (
                              <Spinner
                                animation="border"
                                variant="primary"
                                className={styles.spinner}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </Accordian>
                  </>
                )}
              {incident.status === incidentStatus.initiated && isAdmin && (
                <>
                  <div className={styles.buttons}>
                    <Button
                      variant="secondary"
                      size="md"
                      icon="cancel"
                      value={t(keys.action.DISMISS)}
                      onClick={() => {
                        openConfirmationModal({
                          title: t(keys.action.DISMISS_VARIABLE, {
                            variable: t(keys.common.INCIDENT),
                          }),
                          description: t(keys.action.DISMISS_CONFIRMATION, {
                            variable: t(keys.common.INCIDENT),
                          }),
                          variant: 'danger',
                          buttonText: t(keys.action.DISMISS),
                          onSubmit: () => {
                            publishIncident({
                              variables: {
                                id: incident.id,
                                status: incidentStatus.invalid,
                              },
                            });
                            reviewIncident({
                              variables: {
                                incidentId: incident.id,
                                status: incidentStatus.invalid,
                              },
                            });
                          },
                        });
                      }}
                    />
                    <Button
                      variant="danger"
                      size="md"
                      value={t(keys.action.DEESCALATE)}
                      icon="arrow_circle_down"
                      onClick={() => {
                        openConfirmationModal({
                          title: t(keys.action.DEESCALATE_VARIABLE, {
                            variable: t(keys.common.INCIDENT),
                          }),
                          description: t(keys.action.DEESCALATE_CONFIRMATION, {
                            variable: t(keys.common.INCIDENT),
                          }),
                          variant: 'warning',
                          buttonText: t(keys.action.DEESCALATE),
                          onSubmit: () => {
                            publishIncident({
                              variables: {
                                id: incident.id,
                                status: incidentStatus.closed,
                              },
                            });
                            reviewIncident({
                              variables: {
                                incidentId: incident.id,
                                status: incidentStatus.closed,
                              },
                            });
                          },
                        });
                      }}
                    />
                    <Button
                      disabled={submitDisabled}
                      variant="success"
                      size="md"
                      icon="check_circle"
                      value={t(keys.action.APPROVE)}
                      onClick={() => {
                        openConfirmationModal({
                          title: t(keys.action.CONFIRM_CHANGES),
                          description: t(keys.action.PUBLISH_CONFIRMATION),
                          variant: 'warning',
                          buttonText: t(keys.action.APPROVE),
                          onSubmit: () => {
                            const timeOfDay = incident.dateOfIncident
                              .split(' ')[1]
                              .split('.')[0];
                            publishIncident({
                              variables: {
                                id: incident.id,
                                timeOfDay,
                                status: incidentStatus.investigation,
                                dueDate:
                                  incident.type === 'Aviation'
                                    ? risk.dueDate
                                    : undefined,
                              },
                            }).then(() => {
                              reviewIncident({
                                variables: {
                                  incidentId: incident.id,
                                  status: incidentStatus.investigation,
                                },
                              }).then(() => {
                                refetchIncident();
                              });
                              setIsEditingTitle(false);
                            });
                          },
                        });
                      }}
                    />
                  </div>
                  <Text
                    noMargin
                    weight="semiBold"
                    size="sm"
                    color="secondaryLight"
                    textAlign="right"
                  >
                    {submitDisabled
                      ? t(keys.action.SUBMIT_DISABLED_MESSAGE)
                      : ''}
                  </Text>
                </>
              )}
              {notesStatuses.includes(incident.status) && (
                <Accordian title={t(keys.common.NOTES)} icon="text_snippet">
                  <Notes
                    parentId={incidentId}
                    type={incident.__typename}
                    setNotes={setNotes}
                  />
                  <Text noMargin>{incident.notes}</Text>
                </Accordian>
              )}
              {incident.status === incidentStatus.closed &&
                !incident?.observation &&
                incident?.participants
                  .map(({ id }) => id)
                  .includes(user?.id) && (
                  <Button
                    key={id}
                    className={styles.submitButton}
                    variant="primary"
                    size="md"
                    onClick={() => {
                      openModal({
                        modalName: modals.observation,
                        variables: {
                          participants: [...incident?.participants],
                          workspaceId,
                          templateId: observationTemplate?.id,
                          questions:
                            observationTemplate?.categories[0]?.questions || [],
                          dateTime: new moment().format('YYYY-MM-DD HH:mm'),
                          incidentId: incident?.id,
                        },
                      });
                    }}
                    icon={'content_paste_search'}
                    value={`${t(keys.action.CREATE_VARIABLE, {
                      variable: t(keys.common.OBSERVATION),
                    })}`}
                  />
                )}
              {!!incident?.closer?.id && !!incident?.closerSignatureUrl ? (
                <Accordian title="Closing Details" icon="check_circle">
                  <div className={styles.flex}>
                    <Text noMargin weight="semiBold">
                      Total Downtime (hours)
                    </Text>
                    <Text weight="semiBold" noMargin>
                      {incident.downTimeHrs || 0}
                    </Text>
                  </div>
                  <div className={styles.flex}>
                    <Text textAlign="right" noMargin weight="semiBold">
                      Total Lost Time (hours)
                    </Text>
                    <Text weight="semiBold" noMargin>
                      {incident.lostTimeHrs || 0}
                    </Text>
                  </div>
                  <br />
                  <div>
                    <Text noMargin>
                      {t(keys.incidents.INCIDENT_CLOSED_ON, {
                        variable: `${getLocalTime(incident.closedAt).format(
                          'LLLL',
                        )} ${t(keys.common.BY)} ${incident.closer.firstName} ${
                          incident.closer.lastName
                        }`,
                      })}
                    </Text>
                    <img
                      className={styles.signature}
                      alt="signature"
                      src={incident.closerSignatureUrl}
                    />
                  </div>
                </Accordian>
              ) : (
                incident.status === incidentStatus.investigation && (
                  <div className={styles.footer}>
                    <div className={styles.flex}>
                      <Text noMargin weight="semiBold">
                        Total Lost Time (hours)
                      </Text>
                      <Form.Control
                        className={styles.number}
                        type="number"
                        value={lostTimeHrs}
                        onChange={(e) =>
                          setLostTimeHrs(parseInt(e.target.value))
                        }
                        onBlur={() =>
                          updateIncident({
                            variables: { id: incident.id, lostTimeHrs },
                          })
                        }
                      />
                      <Text noMargin weight="semiBold">
                        Total Downtime (hours)
                      </Text>
                      <Form.Control
                        value={downTimeHrs}
                        type="number"
                        className={styles.number}
                        onChange={(e) =>
                          setDownTimeHrs(parseInt(e.target.value))
                        }
                        onBlur={() =>
                          updateIncident({
                            variables: { id: incident.id, downTimeHrs },
                          })
                        }
                      />
                    </div>

                    <Button
                      variant="danger"
                      align={'right'}
                      size="md"
                      disabled={closeDisabled}
                      onClick={() => {
                        signatureModalVar({
                          title: t(keys.action.REQUIRED, {
                            variable: t(keys.common.SIGNATURE),
                          }),
                          message: t(keys.incidents.CLOSE_INCIDENT_MESSAGE),
                          variant: 'warning',
                          buttonText: t(keys.incidents.CLOSE_INCIDENT),
                          show: true,
                          onSubmit: (url) => {
                            publishIncident({
                              variables: {
                                id: incident.id,
                                status: incidentStatus.complete,
                                closerSignatureUrl: url,
                              },
                            }).then(() => {
                              reviewIncident({
                                variables: {
                                  incidentId: incident.id,
                                  status: incidentStatus.complete,
                                },
                              });
                              setTimeout(() => {
                                showToast({
                                  title: incident.title,
                                  message: t(keys.incidents.INCIDENT_CLOSED),
                                  variant: toastVariant.info,
                                  time: toastLength.md,
                                });
                              }, 1000);
                            });
                          },
                        });
                      }}
                      value={t(keys.incidents.CLOSE_INCIDENT)}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      <IncidentSidebar
        onClose={() => setSidebarOpen(false)}
        inspections={inspections}
        incidentImages={combinedImages}
        refetch={() => refetchIncident()}
        className={!sidebarOpen && isMobile && styles.hide}
        incident={incident}
        title={incident.title}
        notes={notes}
      />
    </div>
  );
}
