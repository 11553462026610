import styles from './IncidentReportAviation.module.scss';
import RiskMatrix from '../../components/RiskMatrix';
import { useIncidents } from '../../hooks/offline-hooks/incidentHook';
import { incidentStatus } from '../../constants/strings';
import React, { useState } from 'react';
import { Text } from '../../components/typography';
import { Form } from 'react-bootstrap';
import { aviationJSONQuestions } from '../../utilities/incidents';
import { adminAviationFields } from '../../utilities/incidents';
import Icon from '../../components/Icon';

export default function IncidentReportAdmin({ incident }) {
  const { updateIncident } = useIncidents();
  const [inputs, setInputs] = useState({
    cfn: false,
    tsb: false,
    dna: false,
  });

  const updateAviationQuestions = (key, newValue) => {
    let prev;
    try {
      prev = JSON.parse(incident.aviationQuestions);
    } catch (e) {
      console.error('Error parsing aviation questions:', e);
      prev = {};
    }

    setInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs, [key]: newValue };
      const aviationQuestions = { ...prev, ...updatedInputs };

      updateIncident({
        variables: {
          id: incident.id,
          aviationQuestions: JSON.stringify(aviationQuestions),
        },
      });

      return updatedInputs;
    });
  };

  const selector = (title, target) => {
    return (
      <div className={styles.field}>
        <Text noMargin weight="bold">
          {title}
        </Text>
        {incident.status === incidentStatus.complete ? (
          <Text noMargin>{!!inputs[target] ? 'Yes' : 'No'}</Text>
        ) : (
          <Form.Group>
            <Form.Check
              inline
              type="radio"
              name={target}
              label="Yes"
              checked={inputs[target]}
              onChange={() => {
                updateAviationQuestions(target, true);
                if (target === 'tsb') {
                  updateIncident({
                    variables: { id: incident.id, reportable: true },
                  });
                }
              }}
            />
            <Form.Check
              inline
              type="radio"
              name={target}
              label="No"
              checked={!inputs[target]}
              onChange={() => {
                updateAviationQuestions(target, false);
                if (target === 'tsb') {
                  updateIncident({
                    variables: { id: incident.id, reportable: false },
                  });
                }
              }}
            />
          </Form.Group>
        )}
      </div>
    );
  };
  return (
    <div>
      <div>
        <Text noMargin weight="bold">
          Select Impact and Probability
        </Text>
        <RiskMatrix
          showMessage={false}
          impact={incident.impact}
          probability={incident.probability}
          onChange={(impact, probability) =>
            updateIncident({
              variables: {
                id: incident.id,
                impact,
                probability,
              },
            })
          }
        />
        <div className={styles.spacer} />
        {selector(adminAviationFields.cfn, aviationJSONQuestions.CFN)}
        {selector(adminAviationFields.dna, aviationJSONQuestions.DNA)}
        {selector(adminAviationFields.tsb, aviationJSONQuestions.TSB)}
        {inputs.tsb === true && (
          <div className={styles.contactCard}>
            <div className={styles.flex}>
              <Icon opacity={0.5} style={{ fontSize: '1rem' }}>
                link
              </Icon>
              <Text
                noMargin
                weight="semiBold"
                color="accentPrimary"
                hover
                onClick={() =>
                  window.open(
                    'https://www.tsb.gc.ca/eng/form/air-transportation-occurrence-re',
                  )
                }
              >
                Transportation Safety Board
              </Text>
              <Icon>right_arrow</Icon>
            </div>
            <div className={styles.flex}>
              <Icon opacity={0.5} style={{ fontSize: '1rem' }}>
                phone
              </Icon>
              <Text weight="semiBold" noMargin>
                819-994-3741 or 1-800-387-3557
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
