import { modals } from '../../providers/modals';
import { useModal } from '../../providers/ModalProvider';
import styles from './CreateCPAModal.module.scss';
import Modal from '../../components/modals/Modal';
import { Text } from '../../components/typography';
import UserSelector from '../../components/UserSelector';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import RiskMatrix from '../../components/RiskMatrix';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useState } from 'react';
import QuickFileDropzone from '../../components/image_uploads/QuickFileDropzone';
import { Spinner } from 'react-bootstrap';
import {
  calculateRiskString,
  cpaStatusTypes,
  correctiveActionTypes,
  correctiveActionTimelines,
  getDueDate,
} from '../../utilities/audits';
import { showToast } from '../../graphql/cache/modal';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { format } from 'date-fns';
import UserMultiSelector from '../../components/UserMultiSelector';
import { Button } from '../../components';

const addAttachmentMutation = loader('./CreateCPAModal.attachment.graphql');
const addCorrectiveActionMutation = loader('./CreateCPAModal.create.graphql');
const addCPAParticipantMutation = loader('./CPAPage.user.graphql');

export default function CreateCPAModal() {
  const { modalState, closeModal, updateModal, openConfirmationModal } =
    useModal();
  const [addAttachment] = useMutation(addAttachmentMutation);
  const { t } = useTranslation();
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [addCorrectiveAction] = useMutation(addCorrectiveActionMutation);
  const [addParticipant] = useMutation(addCPAParticipantMutation);
  const { user } = useCurrentUser();
  const navigate = useNavigate();

  const {
    show,
    isObservation,
    probability,
    impact,
    narrative,
    attachments = [],
    assignee,
    riskLevel,
    title,
    questionId,
    inspectionId,
    onSubmit,
    parentId,
    navigation = false,
    participants = [],
  } = modalState.CPAModal;

  const onHide = () => closeModal({ modalName: modals.CPA });

  const update = (variables) => {
    updateModal({
      modalName: modals.CPA,
      variables: { ...variables },
    });
  };

  const submitDisabled = !isObservation
    ? !impact || !probability || !assignee || !narrative
    : !assignee || !narrative;

  return (
    <Modal
      disableCloseOnSubmit
      open={show || false}
      onClose={onHide}
      title={`Create ${
        isObservation ? 'Opportunity for Improvement' : 'Finding '
      }`}
      submitDisabled={submitDisabled}
      onSubmit={() => {
        openConfirmationModal({
          title: `Submit ${
            isObservation ? 'Opportunity for Improvement' : 'Finding'
          } `,
          description: `Are you sure you want to submit this ${
            isObservation ? 'OFI' : 'Finding'
          }? This will assign action items and cannot be edited once submitted.`,
          variant: 'success',
          onSubmit: async () => {
            const interimDays = correctiveActionTimelines(
              correctiveActionTypes.interim,
              riskLevel === 'OBSERVATION' ? 'LOW' : riskLevel,
            );
            const finalDays = correctiveActionTimelines(
              correctiveActionTypes.final,
              riskLevel === 'OBSERVATION' ? 'LOW' : riskLevel,
            );
            const correctiveAction = modalState.CPAModal;
            async function handleCorrectiveAction() {
              try {
                const {
                  data: {
                    addCorrectiveAction: { id: correctiveActionId },
                  },
                } = await addCorrectiveAction({
                  variables: {
                    ...correctiveAction,
                    assigneeId: assignee.id,
                    interimDueDate: getDueDate(interimDays),
                    finalDueDate: getDueDate(finalDays),
                    status: cpaStatusTypes.INTERIM.key,
                    inspectionId,
                    questionId,
                    parentId,
                    priority: riskLevel === 'OBSERVATION' ? 'LOW' : riskLevel,
                    participants,
                  },
                });

                await Promise.all(
                  attachments.map(({ url, name, extension, size }) =>
                    addAttachment({
                      variables: {
                        correctiveActionId,
                        url,
                        name,
                        extension,
                        size,
                      },
                    }),
                  ),
                );

                await Promise.all(
                  participants.map(({ id: participantId }) =>
                    addParticipant({
                      variables: {
                        userId: participantId,
                        action: 'PARTICIPANT',
                        correctiveActionId,
                      },
                    }),
                  ),
                );

                onHide();
                onSubmit({
                  failNotes: narrative,
                  userId: assignee.id,
                });

                if (navigation) {
                  navigate(`/${paths.cpa}/${correctiveActionId}`);
                }

                showToast({
                  title: 'Success!',
                  message: 'Corrective Preventative Actions Assigned!',
                });
              } catch (error) {
                console.error('Error handling corrective action:', error);
              }
            }

            handleCorrectiveAction();
          },
        });
      }}
    >
      <div className={styles.container}>
        {!!title && (
          <div className={styles.question}>
            <Text color="red" weight="semiBold" noMargin>
              Question
            </Text>
            <Text weight="bold" size="md" noMargin>
              {title}
            </Text>
          </div>
        )}
        <div className={styles.topButtons}>
          <Button
            value={'Finding'}
            className={styles.button}
            icon={!isObservation ? 'check' : 'cancel'}
            variant={isObservation ? 'secondary' : 'success'}
            onClick={() =>
              update({
                isObservation: false,
                riskLevel: null,
                impact: null,
                probability: null,
              })
            }
          />
          <Button
            value={'Improvement'}
            icon={isObservation ? 'check' : 'cancel'}
            variant={!isObservation ? 'secondary' : 'success'}
            className={styles.button}
            onClick={() =>
              update({
                isObservation: true,
                riskLevel: 'OBSERVATION',
                impact: null,
                probability: null,
              })
            }
          />
        </div>
        <Text weight="semiBold" noMargin>
          Assignee
        </Text>
        <UserSelector
          type="assignee"
          userQuery="all"
          selected={assignee}
          onSelectionChanged={(assignee) => {
            update({ assignee });
          }}
          onClose={() => {
            update({ assignee: null });
          }}
        />
        <Text noMargin weight="semiBold">
          Participants
        </Text>
        <UserMultiSelector
          onUserAdded={(user) => {
            update({ participants: [...participants, user] });
          }}
          className={styles.team}
          type="all"
          selected={participants}
          onUserRemoved={(user) => {
            update({
              participants: participants?.filter((u) => u.id !== user.id),
            });
          }}
          usersToLeaveOut={[user]}
        />
        <Text weight="semiBold" noMargin>
          Narrative
        </Text>
        <div>
          <Form.Control
            as="textarea"
            rows={5}
            value={narrative}
            onChange={(e) => update({ narrative: e.target.value })}
            onBlur={() => update({ narrative })}
          />
        </div>

        <div className={styles.attachmentContainer}>
          {loadingAttachment ? (
            <div className={styles.spinner}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div>
              {!attachments?.length ? (
                <Text weight="semiBold">{t(keys.common.ATTACHMENTS)}</Text>
              ) : null}
              <QuickFileDropzone
                setLoading={setLoadingAttachment}
                icon={attachments?.length > 0}
                iconText={t(keys.common.ATTACHMENTS)}
                className={styles.attachmentText}
                onDelete={(id) => {
                  const updatedAttachments = attachments.filter(
                    (a) => a.id !== id,
                  );
                  update({ attachments: updatedAttachments });
                }}
                readOnly={false}
                attachments={attachments || []}
                loading={loadingAttachment}
                onSubmit={({ url, name, extension, size }) => {
                  const attachment = {
                    url,
                    name,
                    extension,
                    size,
                    creator: user,
                    dateCreated: format(new Date(), 'yyyy-MM-dd'),
                  };
                  update({ attachments: [...attachments, attachment] });
                }}
              />
            </div>
          )}
        </div>
        {!isObservation ? (
          <div className={styles.matrix}>
            <Text weight="semiBold" noMargin>
              Threat Level
            </Text>
            <RiskMatrix
              showMessage={false}
              impact={impact}
              probability={probability}
              onChange={(impact, probability) => {
                const risk = calculateRiskString(impact * probability);
                update({ impact, probability, riskLevel: risk });
              }}
            />
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
