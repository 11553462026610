import React, { useState, useEffect } from 'react';
import styles from './IncidentReportAviation.module.scss';
import { Text } from '../../components/typography';
import { Form } from 'react-bootstrap';
import {
  ATAchapters,
  incidentAviationQuestions,
  aviationPersonnelQuestions,
  aviationJSONQuestions,
  yesNoQuestions,
  aviationOptions,
} from '../../utilities/incidents';
import UserSelector from '../../components/UserSelector';
import Select from 'react-select';
import { aviationFields } from '../../utilities/incidents';
import { useIncidents } from '../../hooks/offline-hooks/incidentHook';
import DropdownCombo from '../../components/dropdowns/DropdownCombo';
import { dropdownTypes } from '../../components/dropdowns/dropdown';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import TextWithIcon from '../../components/typography/TextWithIcon';
import InspectionItemAsset from '../../components/inspections/inspection_items/InspectionItemAsset';
import { getLocalTime } from '../../utilities/time';

export const IncidentReportAviation = ({ incident, readOnly }) => {
  const { updateIncident } = useIncidents();
  const { workspaceVehicles } = useWorkspace();
  const aviationResults = JSON.parse(incident.aviationQuestions || '{}');

  const initialState = () => {
    const personnel = aviationPersonnelQuestions.personnel.reduce(
      (acc, key) => {
        acc[key] =
          aviationResults.personnel &&
          aviationResults.personnel[key] !== undefined
            ? aviationResults.personnel[key]
            : 0;
        return acc;
      },
      {},
    );

    const aviationData = Object.keys(aviationFields).reduce((acc, key) => {
      acc[key] =
        key in aviationResults
          ? aviationResults[key]
          : yesNoQuestions.includes(key)
          ? false
          : undefined;
      return acc;
    }, {});

    const selectedOptions = aviationResults.selectedOptions || [];
    return {
      personnel,
      vehicle: incident.vehicle,
      totalPersonnel: 0,
      cfn: false,
      tsb: false,
      dna: false,
      units: aviationResults.units,
      ...aviationData,
      selectedOptions,
    };
  };

  const [inputs, setInputs] = useState(initialState());

  useEffect(() => {
    const totalPersonnel = aviationPersonnelQuestions.personnel.reduce(
      (acc, role) => {
        const count = parseInt(inputs.personnel[role], 10);
        return acc + (isNaN(count) ? 0 : count);
      },
      0,
    );

    setInputs((prev) => ({
      ...prev,
      totalPersonnel,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.injury, inputs.personnel]);

  const handlePersonnelChange = (role, value) => {
    const updatedValue = value?.replace(/\D/, '');
    setInputs((prevInputs) => {
      const updatedInputs = {
        ...prevInputs,
        personnel: {
          ...prevInputs.personnel,
          [role]: updatedValue,
        },
      };
      const aviationQuestions = JSON.stringify(updatedInputs, null, 0);
      updateIncident({
        variables: {
          id: incident.id,
          aviationQuestions,
        },
      });
      return updatedInputs;
    });
  };

  const updateAviationQuestions = (key, newValue) => {
    setInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs, [key]: newValue };
      const aviationQuestions = JSON.stringify(updatedInputs, null, 0);

      updateIncident({
        variables: {
          id: incident.id,
          aviationQuestions,
        },
      });

      return updatedInputs;
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setInputs((prevInputs) => {
      const updatedSelectedOptions = checked
        ? [...prevInputs.selectedOptions, name]
        : prevInputs.selectedOptions.filter((option) => option !== name);

      return {
        ...prevInputs,
        selectedOptions: updatedSelectedOptions,
      };
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.spacer} />
      <div className={styles.field}>
        <Text weight="bold" truncate noMargin>
          Date and Time of Departure (UTC)
        </Text>
        {readOnly ? (
          <Text noMargin>
            {getLocalTime(
              aviationResults[aviationJSONQuestions.DEPARTURE_DATE],
            ).format('dddd MMMM Do, YYYY HH:mm a')}
          </Text>
        ) : (
          <input
            className={styles.date}
            value={inputs.departureDate}
            type="datetime-local"
            onChange={(e) => {
              updateAviationQuestions(
                aviationJSONQuestions.DEPARTURE_DATE,
                e.target.value,
              );
            }}
          />
        )}
      </div>
      <div>
        <Text weight="bold" truncate noMargin>
          Aircraft
        </Text>
        {readOnly ? (
          <div className={styles.left}>
            <InspectionItemAsset vehicle={incident.vehicle} />
          </div>
        ) : (
          <DropdownCombo
            type={dropdownTypes.ASSET}
            items={workspaceVehicles}
            selected={inputs?.vehicle}
            preventStateChange
            onChange={(vehicle) => {
              updateIncident({
                variables: { id: incident.id, vehicleId: vehicle?.id },
              });
              updateAviationQuestions('vehicle', vehicle);
            }}
            onRemove={() =>
              updateIncident({
                variables: { id: incident.id, vehicleId: null },
              })
            }
          />
        )}
        <div className={styles.spacer} />
        <div className={styles.field}>
          <Text noMargin weight="bold">
            {aviationFields.category}
          </Text>
          {readOnly ? (
            <Text noMargin>{inputs.category?.label || 'Not Selected'}</Text>
          ) : (
            <Select
              isClearable
              value={inputs.category}
              options={ATAchapters}
              onChange={(value) => {
                updateAviationQuestions('category', value);
              }}
            />
          )}
        </div>
      </div>
      {Object.entries(incidentAviationQuestions).map(([key, questions]) => (
        <div key={key} className={styles.aviation}>
          {questions.map(({ convertToQuery, title, items }) => {
            return (
              <div className={styles.field} key={title}>
                <Text noMargin weight="bold">
                  {title}
                </Text>
                {readOnly ? (
                  <Text noMargin>
                    {aviationResults[convertToQuery] || 'None'}
                  </Text>
                ) : (
                  <div className={styles.input}>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      onChange={(e) =>
                        setInputs({
                          ...inputs,
                          [convertToQuery]: e.target.value,
                        })
                      }
                      value={inputs[convertToQuery] || undefined}
                      onBlur={(e) =>
                        updateAviationQuestions(convertToQuery, e.target.value)
                      }
                    />
                    {!!items ? (
                      readOnly ? (
                        <Text>{inputs.units.key}</Text>
                      ) : (
                        <Select
                          className={styles.dropdown}
                          options={items.map((i) => ({ label: i, key: i }))}
                          value={inputs.units}
                          onChange={(value) =>
                            updateAviationQuestions('units', value)
                          }
                        />
                      )
                    ) : null}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
      <Text weight="bold" noMargin>
        Did any of the following occur? Select all that apply.
      </Text>
      <div className={styles.spacer} />
      {readOnly ? (
        <Text>{inputs.selectedOptions?.join(', ') || 'None'}</Text>
      ) : (
        <div className={styles.flex}>
          {aviationOptions.map((o) => (
            <Form.Check
              key={o}
              label={o}
              name={o}
              type="checkbox"
              value={o}
              onChange={(e) => {
                if (readOnly) {
                  return;
                }
                handleCheckboxChange(e);
              }}
              checked={inputs?.selectedOptions?.includes(o)}
            />
          ))}
        </div>
      )}
      <br />
      <TextWithIcon icon="people_outline" size="lg" noMargin weight="bold">
        Personnel
      </TextWithIcon>
      <br />
      <div className={styles.field}>
        <Text weight="bold" truncate noMargin>
          {aviationFields.pilot}
        </Text>
        <UserSelector
          readOnly={readOnly}
          selected={inputs?.pilot}
          onSelectionChanged={(user) => updateAviationQuestions('pilot', user)}
          onClose={() => updateAviationQuestions('pilot', null)}
        />
        <Text weight="bold" truncate noMargin>
          {aviationFields.nonFlyingPilot}
        </Text>
        <UserSelector
          readOnly={readOnly}
          selected={inputs?.nonFlyingPilot}
          onSelectionChanged={(user) => {
            updateAviationQuestions('nonFlyingPilot', user);
          }}
          onClose={() => updateAviationQuestions('nonFlyingPilot', null)}
        />
      </div>

      <div className={styles.personnel}>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            {aviationPersonnelQuestions.personnel?.map((role) => (
              <div key={role}>
                <Text weight="bold" truncate noMargin className={styles.text}>
                  {role}
                </Text>
                <div className={styles.spacer} />
                {readOnly ? (
                  <Text noMargin>{inputs?.personnel[role]}</Text>
                ) : (
                  <Form.Control
                    type="text"
                    value={inputs?.personnel[role]}
                    onChange={(e) =>
                      handlePersonnelChange(role, e.target.value)
                    }
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <br />
        <Text weight="bold">{`Total Personnel: ${inputs.totalPersonnel}`}</Text>
      </div>
    </div>
  );
};
