import { Form } from 'react-bootstrap';
import styles from './Notes.module.scss';
import Icon from '../Icon';
import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import SimpleUserCard from '../SimpleUserCard';
import { Text } from '../typography';
import { getLocalTime } from '../../utilities/time';
import { useCurrentUser } from '../../providers/UserProvider';

const commentQuery = loader('./Notes.fetch.graphql');
const addCommentMutation = loader('./Notes.add.graphql');

export default function Notes({
  type,
  parentId,
  readOnly,
  setNotes = () => {},
}) {
  const { user } = useCurrentUser();
  const [newComment, setNewComment] = useState({});
  const { refetch, data: { comments = [{}] } = {} } = useQuery(commentQuery, {
    variables: {
      options: {
        filters: [
          { field: 'parentId', operator: 'eq', value: `${parentId}` },
          { field: 'type', operator: 'eq', value: type },
        ],
      },
    },
  });
  const [addComment] = useMutation(addCommentMutation);
  useEffect(() => {
    if (comments && comments?.length > 0) {
      setNotes(comments);
    }
  }, [comments, setNotes]);

  return (
    <div className={styles.container}>
      {!readOnly && (
        <div className={styles.input}>
          <img src={user.avatarUrl} className={styles.avatar} alt="user" />
          <Form.Control
            placeholder="Leave a note.."
            as="textarea"
            rows={2}
            onChange={(e) =>
              setNewComment({ ...newComment, text: e.target.value })
            }
            value={newComment.text || ''}
          />
          <Icon
            hover
            disabled={!!!newComment.text}
            onClick={() =>
              addComment({
                variables: { ...newComment, type, parentId },
              }).then(() => {
                setNewComment({});
                refetch();
              })
            }
            color="blue"
          >
            send
          </Icon>
        </div>
      )}
      <div className={styles.comments}>
        {comments?.map(({ creator, text, dateCreated, id }) => (
          <div className={styles.card} key={id}>
            <SimpleUserCard
              mediumAvatar
              user={creator}
              smallText={getLocalTime(dateCreated).format(
                'dddd MMMM Do, YYYY HH:mm',
              )}
            />
            <Text noMargin className={styles.text}>
              {text}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}
