import styles from './CPAPage.module.scss';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Text } from '../../components/typography';
import { calculateRisk } from '../../utilities/riskMatrix';
import Accordian from '../../components/Accordian';
import {
  pastDue,
  cpaStatusTypes,
  riskColors,
  CPAActionTypes,
} from '../../utilities/audits';
import { getDaysFromNow, getLocalTime } from '../../utilities/time';
import CorrectiveAction from './CorrectiveAction';
import SimpleUserCard from '../../components/SimpleUserCard';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import Label from '../../components/action_items/Label';
import { getRoute, paths } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useModal } from '../../providers/ModalProvider';
import { Spinner } from 'react-bootstrap';
import Icon from '../../components/Icon';
import { Rating } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import { useCurrentUser } from '../../providers/UserProvider';
import { showToast } from '../../graphql/cache/modal';
import { customColors } from '../../utilities';
import CircleIcon from '@mui/icons-material/Circle';
import QuickFileDropzone from '../../components/image_uploads/QuickFileDropzone';
import { statusStringLookup } from '../../components/action_items/action_item_utilities';
import { modals } from '../../providers/modals';
import TextWithIcon from '../../components/typography/TextWithIcon';
import UserMultiSelector from '../../components/UserMultiSelector';
import { Form } from 'react-bootstrap';

const CPAQuery = loader('./CPAPage.fetch.graphql');
const updateCPAMutation = loader('./CPAPage.update.graphql');
const addCPAUserMutation = loader('./CPAPage.user.graphql');
const deleteCPAUserMutation = loader('./CPAPage.deleteUser.graphql');
const addActionItemMutation = loader('./CPAPage.addActionItem.graphql');
const addAttachmentMutation = loader('./CreateCPAModal.attachment.graphql');
const updateActionItemMutation = loader(
  './CorrectiveAction.updateActionItem.graphql',
);

export default function CPAPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [updateCPA] = useMutation(updateCPAMutation);
  const [addCPAUser] = useMutation(addCPAUserMutation);
  const [addActionItem] = useMutation(addActionItemMutation);
  const [updateActionItem] = useMutation(updateActionItemMutation);
  const [deleteCPAUser] = useMutation(deleteCPAUserMutation);
  const { workspaceId } = useWorkspace();
  const { openConfirmationModal, openModal } = useModal();
  const { user, isAdmin } = useCurrentUser();
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [addAttachment] = useMutation(addAttachmentMutation);
  const [effectivenessReview, setEffectivenessReview] = useState(true);

  const {
    refetch,
    data: { correctiveActions: [correctiveAction] = [{}] } = {},
  } = useQuery(CPAQuery, {
    skip: !id,
    variables: {
      options: {
        filters: [{ field: 'id', operator: 'eq', value: `${id}` }],
      },
    },
  });

  const {
    isObservation,
    question,
    inspection,
    narrative,
    riskLevel,
    impact,
    probability,
    interimActionItem,
    finalActionItem,
    status,
    creator,
    dateCreated,
    assignee,
    reviewActionItem,
    acceptanceActionItem,
    users,
    attachments,
    parentId,
    childId,
    externalId,
    rootCause,
  } = correctiveAction || {};

  const risk = impact * probability;
  const [rootcause, setRootCause] = useState(rootCause);

  const { COMPLETE, DENIED, ACCEPTED, FINAL, REVIEWED, INTERIM } =
    cpaStatusTypes;

  const {
    accepter: accepterAction,
    reviewer: reviewerAction,
    participant: participantAction,
  } = CPAActionTypes;

  const {
    isDenied,
    acceptanceNotes,
    isClosed,
    showFinal,
    canAccept,
    accepter,
    reviewer,
    readOnly,
    participants,
    isAssignee,
  } = useMemo(() => {
    const isClosed = status === COMPLETE.key || status === DENIED.key;
    const isDenied = status === DENIED.key;
    const showFinal =
      !!parentId || riskLevel === 'HIGH'
        ? !!interimActionItem?.locked && !!rootCause
        : !!interimActionItem?.locked;
    const canAccept = !!interimActionItem?.locked && !!finalActionItem?.locked;
    const participants =
      users
        ?.filter((u) => u.action === participantAction)
        .map(({ user }) => user) || [];

    const accepter = users?.find((u) => u.action === accepterAction) || [];
    const reviewer = users?.find((u) => u.action === reviewerAction) || [];
    const acceptanceNotes =
      users?.find((u) => u.action === accepterAction)?.notes || null;
    const isAssignee = correctiveAction.assignee?.id === user?.id;

    const readOnly = isDenied || isClosed;

    return {
      isClosed,
      isDenied,
      showFinal,
      canAccept,
      accepter,
      reviewer,
      readOnly,
      participants,
      acceptanceNotes,
      isAssignee,
    };
  }, [
    status,
    COMPLETE.key,
    DENIED.key,
    parentId,
    riskLevel,
    interimActionItem?.locked,
    rootCause,
    finalActionItem?.locked,
    users,
    correctiveAction.assignee?.id,
    user?.id,
    participantAction,
    accepterAction,
    reviewerAction,
  ]);

  useEffect(() => {
    if (correctiveAction) {
      setRootCause(rootCause);
    }
  }, [correctiveAction, rootCause]);

  const rootCauseRequired = !!parentId || riskLevel === 'HIGH';

  return !correctiveAction?.id ? (
    <Spinner animation="border" variant="primary" />
  ) : (
    <div className={styles.container}>
      <div className={styles.page}>
        <div className={styles.left}>
          <Text size="lg" weight="bold" noMargin>
            {'Corrective Preventative Actions'}
          </Text>
          <div className={styles.header}>
            <div className={styles.flexRow}>
              <Text noMargin size="lg" weight="semiBold">
                {externalId}
              </Text>
              <div>
                {!!parentId ? (
                  <TextWithIcon
                    color="accentPrimary"
                    weight="bold"
                    size="sm"
                    hover
                    link
                    onClick={() => navigate(`/${paths.cpa}/${parentId}`)}
                    noMargin
                  >
                    Initial Finding
                  </TextWithIcon>
                ) : null}
                {!!childId ? (
                  <TextWithIcon
                    link
                    size="sm"
                    color="accentPrimary"
                    weight="bold"
                    hover
                    onClick={() => navigate(`/${paths.cpa}/${childId}`)}
                    noMargin
                  >
                    Repeat Finding
                  </TextWithIcon>
                ) : null}
              </div>
            </div>

            <div className={styles.badgeContainer}>
              <div>
                <Text
                  textAlign="center"
                  weight="semiBold"
                  color="secondary"
                  noMargin
                >
                  {isObservation
                    ? 'Opportunity for Improvement'
                    : 'Finding Threat Level'}
                </Text>
                <div className={styles.row}>
                  <Icon
                    style={{
                      color: riskColors[riskLevel],
                      fontSize: '2.5rem',
                    }}
                  >
                    warning
                  </Icon>
                  <Text
                    color={calculateRisk(risk || 'OFI')?.textColor}
                    weight="bold"
                    noMargin
                    size="xl"
                    noSelect
                    textAlign="center"
                  >
                    {calculateRisk(risk || 'OFI')?.level.toUpperCase()}
                  </Text>
                </div>
              </div>

              {!isObservation ? (
                <div className={styles.center}>
                  <div className={styles.rating}>
                    <Text weight="semiBold" textAlign="right" noMargin>
                      Impact
                    </Text>
                    <Rating
                      readOnly
                      defaultValue={impact}
                      max={5}
                      size="small"
                      emptyIcon={
                        <Icon style={{ fontSize: '1rem' }}>circle</Icon>
                      }
                      icon={
                        <CircleIcon
                          sx={{
                            fontSize: '1rem',
                          }}
                        />
                      }
                    />
                  </div>
                  <div className={styles.rating}>
                    <Text weight="semiBold" textAlign="right" noMargin>
                      Probability
                    </Text>
                    <Rating
                      readOnly
                      defaultValue={probability}
                      max={5}
                      size="small"
                      emptyIcon={
                        <Icon style={{ fontSize: '1rem' }}>circle</Icon>
                      }
                      icon={
                        <CircleIcon
                          sx={{
                            fontSize: '1rem',
                          }}
                        />
                      }
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.top}>
              {!!question ? (
                <div>
                  <Text color="secondary" weight="semiBold" noMargin>
                    Question
                  </Text>
                  <Text weight="semiBold" noMargin>
                    {question?.title}
                  </Text>
                </div>
              ) : null}
              <div>
                <Text color="secondary" weight="semiBold" noMargin>
                  Narrative
                </Text>
                <Text className={styles.text} weight="semiBold" noMargin>
                  {narrative}
                </Text>
              </div>
            </div>
          </div>
          <Accordian
            size="md"
            icon="image"
            accordianSize={true}
            title="Attachments"
            backgroundColor={customColors.DARK_GRAY}
            textColor="white"
            messageVariant={'white'}
            iconProps={{ color: 'white' }}
          >
            <div className={styles.files}>
              <QuickFileDropzone
                icon="true"
                readOnly={!isAdmin || readOnly}
                disableAddFile={!isAdmin || readOnly}
                loading={loadingAttachment}
                attachments={attachments || []}
                setLoading={setLoadingAttachment}
                onSubmit={({ url, name, extension }) => {
                  addAttachment({
                    variables: {
                      url,
                      name,
                      extension,
                      correctiveActionId: parseInt(id),
                    },
                  }).then(() => refetch());
                }}
              />
            </div>
          </Accordian>
          <Accordian
            title="Interim Corrective Action"
            iconProps={{
              color: interimActionItem?.locked ? customColors.GREEN : 'red',
            }}
            icon={
              interimActionItem?.locked
                ? 'check_circle'
                : !!interimActionItem?.dueDate &&
                  !!pastDue(finalActionItem?.dueDate)
                ? 'error'
                : undefined
            }
            message={
              interimActionItem?.locked
                ? 'Complete'
                : getDaysFromNow(interimActionItem?.dueDate)
            }
          >
            <CorrectiveAction
              actionItemId={interimActionItem?.id}
              allowExtension={true}
              correctiveAction={correctiveAction}
              onSubmit={() => {
                if ((rootCauseRequired && !!rootCause) || !rootCauseRequired) {
                  updateCPA({
                    variables: {
                      id: parseInt(id),
                      status: FINAL.key,
                    },
                  });
                }
              }}
            />
          </Accordian>
          <Accordian
            title={`Root Cause`}
            message={
              rootCause
                ? 'Complete'
                : !rootCauseRequired
                ? 'Optional'
                : '*Required'
            }
            messageVariant={rootCause || !rootCauseRequired ? 'primary' : 'red'}
            icon={rootCause ? 'check_circle' : null}
            iconProps={{ color: customColors.GREEN_LIGHT }}
          >
            {!rootCause && isAssignee ? (
              <div>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={rootcause}
                  onChange={(e) => {
                    setRootCause(e.target.value);
                  }}
                />
                <br />
                <Button
                  value="Complete"
                  icon="check_circle"
                  disabled={!rootcause}
                  variant="success"
                  align="right"
                  onClick={() => {
                    openConfirmationModal({
                      title: 'Submit Root Cause',
                      variant: 'warning',
                      description:
                        'Are you sure you would like to submit Root Cause? This cannot be edited once submitted.',
                      onSubmit: () => {
                        updateCPA({
                          variables: { id: parseInt(id), rootCause: rootcause },
                        });
                        if (
                          interimActionItem?.locked &&
                          rootCauseRequired &&
                          status === INTERIM.key
                        ) {
                          updateCPA({
                            variables: {
                              id: parseInt(id),
                              status: FINAL.key,
                            },
                          });
                        }
                      },
                    });
                  }}
                />
              </div>
            ) : (
              <Text noMargin>{rootCause || 'No response provided'}</Text>
            )}
          </Accordian>
          {showFinal && (
            <Accordian
              iconProps={{
                color: finalActionItem?.locked ? customColors.GREEN : 'red',
              }}
              title="Final Preventative Action"
              icon={
                finalActionItem?.locked
                  ? 'check_circle'
                  : !!finalActionItem?.dueDate &&
                    !!pastDue(finalActionItem?.dueDate)
                  ? 'error'
                  : undefined
              }
              message={
                finalActionItem?.locked
                  ? 'Complete'
                  : getDaysFromNow(finalActionItem?.dueDate) || null
              }
            >
              <CorrectiveAction
                allowDelegates={false}
                allowExtension={true}
                actionItemId={finalActionItem?.id}
                correctiveAction={correctiveAction}
                disableAssigneeSelect={true}
                onSubmit={() => {
                  const workspaceId = assignee.lastWorkspaceId;
                  try {
                    addActionItem({
                      variables: {
                        title: 'Corrective Action Acceptance Required',
                        description: `${isObservation ? 'OFI' : 'Finding'}: ${
                          question?.title || ''
                        } is complete and requires approval`,
                        priority:
                          riskLevel === 'OBSERVATION' ? 'LOW' : riskLevel,
                        status: 'TODO',
                        cpaId: parseInt(id),
                        inspectionId: inspection?.id,
                        assigneeId: creator.id,
                        type: CPAActionTypes.accept,
                        isCorrective: true,
                        workspaceId,
                      },
                    }).then(async ({ data: { addActionItem } }) => {
                      await updateCPA({
                        variables: {
                          id: parseInt(id),
                          acceptanceActionItemId: addActionItem.id,
                          status: ACCEPTED.key,
                        },
                      });
                    });
                  } catch (error) {
                    showToast({
                      title: 'An error occurred',
                      message: `${error.message}`,
                    });
                  }
                }}
              />
            </Accordian>
          )}
          {!canAccept && !acceptanceActionItem?.id ? (
            <Text color="secondary" size="sm" textAlign="right">
              {status === cpaStatusTypes.INTERIM.key
                ? `${
                    rootCauseRequired ? 'Root Cause and ' : ''
                  }Interim Corrective Action must be completed prior to starting the Final Preventative Action.`
                : status === cpaStatusTypes.FINAL.key
                ? 'Final Preventative Action must be complete prior to acceptance.'
                : null}
            </Text>
          ) : null}
          {canAccept && (
            <Accordian
              title="Acceptance"
              iconProps={{
                color:
                  acceptanceActionItem?.locked && acceptanceNotes === 'ACCEPTED'
                    ? customColors.GREEN
                    : 'red',
              }}
              icon={
                !!acceptanceActionItem?.locked
                  ? acceptanceNotes !== 'ACCEPTED'
                    ? 'cancel'
                    : 'check_circle'
                  : !!acceptanceActionItem?.dueDate &&
                    !!pastDue(acceptanceActionItem?.dueDate)
                  ? 'error'
                  : undefined
              }
              message={
                acceptanceActionItem?.locked ? 'Complete' : 'Not Complete'
              }
            >
              <CorrectiveAction
                leftFooter={
                  isObservation ? (
                    <div className={styles.row}>
                      <Text noMargin weight="semiBold">
                        Effectiveness Review Required?
                      </Text>
                      {acceptanceActionItem?.locked ? (
                        <Text noMargin>
                          {!!reviewActionItem?.id ? 'Yes' : 'No'}
                        </Text>
                      ) : (
                        <div className={styles.check}>
                          <Form.Check
                            label="Yes"
                            name="approval"
                            type={'radio'}
                            defaultChecked={true}
                            onChange={() => {
                              setEffectivenessReview(true);
                            }}
                          />
                          <Form.Check
                            label="No"
                            name="approval"
                            type={'radio'}
                            onChange={() => {
                              setEffectivenessReview(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : null
                }
                actionItemId={acceptanceActionItem?.id}
                footer
                allowEditDueDate
                showDueDate={false}
                allowExtension={false}
                correctiveAction={correctiveAction}
                onDeny={async () => {
                  setEffectivenessReview(false);
                  try {
                    await addCPAUser({
                      variables: {
                        userId: user.id,
                        action: 'ACCEPTER',
                        correctiveActionId: parseInt(id),
                        notes: 'DENIED',
                      },
                    }).then(() => refetch());
                  } catch (error) {
                    showToast({
                      title: 'An error occurred',
                      message: `${error.message}`,
                    });
                  }
                }}
                onSubmit={async () => {
                  try {
                    await addCPAUser({
                      variables: {
                        userId: user.id,
                        action: 'ACCEPTER',
                        correctiveActionId: parseInt(id),
                        notes: 'ACCEPTED',
                      },
                    }).then(() =>
                      updateActionItem({
                        variables: {
                          id: acceptanceActionItem.id,
                          status: statusStringLookup.DONE.key,
                        },
                      }),
                    );
                    if (!!effectivenessReview) {
                      const { data, errors } = await addActionItem({
                        variables: {
                          title: `Effectiveness Review Required`,
                          description: `${
                            !!question ? question.title : ''
                          } ${narrative}`,
                          type: 'CPA-REVIEW',
                          workspaceId,
                          assigneeId: user.id,
                          priority: 'LOW',
                          inspectionId: inspection?.id,
                          isCorrective: true,
                          cpaId: parseInt(id),
                        },
                      });

                      if (errors) {
                        showToast({
                          title: 'Error creating action item',
                          message: `${errors.message}`,
                        });
                        return;
                      }
                      const actionItemId = data?.addActionItem?.id;
                      await updateCPA({
                        variables: {
                          id: parseInt(id),
                          status: REVIEWED.key,
                          reviewActionItemId: actionItemId,
                        },
                      });
                      refetch();
                    } else {
                      await updateCPA({
                        variables: {
                          id: parseInt(id),
                          status: COMPLETE.key,
                        },
                      });
                    }
                  } catch (error) {
                    showToast({
                      title: 'An error occurred',
                      message: `${error.message}`,
                    });
                  }
                }}
              />
            </Accordian>
          )}
          {!!reviewActionItem?.id && (
            <Accordian
              iconProps={{
                color:
                  reviewActionItem?.locked && reviewer?.notes !== DENIED.key
                    ? customColors.GREEN
                    : 'red',
              }}
              icon={
                !!reviewActionItem?.locked
                  ? reviewer?.notes === DENIED.key
                    ? 'cancel'
                    : 'check_circle'
                  : !!reviewActionItem?.dueDate &&
                    !!pastDue(reviewActionItem?.dueDate)
                  ? 'error'
                  : undefined
              }
              messageVariant={
                !reviewActionItem?.dueDate && !reviewActionItem?.locked
                  ? 'yellow'
                  : undefined
              }
              message={
                reviewActionItem?.locked
                  ? 'Complete'
                  : !!reviewActionItem?.dueDate
                  ? getDaysFromNow(reviewActionItem?.dueDate)
                  : 'Due Date Not Selected'
              }
              title="Effectiveness Review"
            >
              <CorrectiveAction
                allowEditDueDate={isAssignee}
                actionItemId={reviewActionItem?.id}
                footer={true}
                allowDelegates={false}
                correctiveAction={correctiveAction}
                onDeny={async () => {
                  try {
                    await addCPAUser({
                      variables: {
                        userId: user.id,
                        action: 'REVIEWER',
                        correctiveActionId: parseInt(id),
                        notes: 'DENIED',
                      },
                    }).then(() => refetch());
                  } catch (error) {
                    showToast({
                      title: 'An error occurred',
                      message: `${error.message}`,
                    });
                  }
                }}
                onSubmit={async () => {
                  await addCPAUser({
                    variables: {
                      userId: user.id,
                      action: 'REVIEWER',
                      correctiveActionId: parseInt(id),
                    },
                  }).then(() => {
                    updateCPA({
                      variables: {
                        id: parseInt(id),
                        status: COMPLETE.key,
                      },
                    });
                  });
                }}
              />
            </Accordian>
          )}
          <br />
        </div>
        <div className={styles.right}>
          <Text noMargin weight="semiBold" size="lg">
            Information
          </Text>
          {isDenied && !childId && (
            <TextWithIcon
              noMargin
              weight="semiBold"
              color="accentPrimary"
              icon="add"
              hover
              onClick={() => {
                openModal({
                  modalName: modals.CPA,
                  variables: {
                    ...correctiveAction,
                    parentId: correctiveAction.id,
                    inspectionId: correctiveAction.inspection?.id ?? null,
                    questionId: correctiveAction.question?.id ?? null,
                    navigation: true,
                  },
                });
              }}
            >
              Create Repeat Finding
            </TextWithIcon>
          )}
          {!!inspection && isAdmin ? (
            <>
              <Text color="secondary" weight="semiBold" size="sm" noMargin>
                Audit
              </Text>
              <div className={styles.link}>
                <TextWithIcon
                  hover
                  onClick={() => {
                    navigate(
                      getRoute(workspaceId, paths.assessment, inspection.id),
                    );
                  }}
                  color="accentPrimary"
                  noMargin
                  weight="semiBold"
                  link
                >
                  {inspection?.template?.title}
                </TextWithIcon>
              </div>
              <div className={styles.sectionLine} />
            </>
          ) : null}
          <Text color="secondary" weight="semiBold" size="sm" noMargin>
            Status
          </Text>
          <Label
            name={cpaStatusTypes[status]?.title}
            color={cpaStatusTypes[status]?.color}
            rowSized={false}
          />
          <div className={styles.sectionLine} />
          <Text color="secondary" weight="semiBold" size="sm" noMargin>
            Assignee
          </Text>
          <SimpleUserCard user={assignee} largeAvatar />
          <div className={styles.sectionLine} />
          <Text color="secondary" weight="semiBold" size="sm" noMargin>
            Participants
          </Text>
          {isClosed ? (
            <div className={styles.participants}>
              {!participants?.length ? (
                <Text noMargin>None</Text>
              ) : (
                participants?.map((user) => (
                  <SimpleUserCard user={user} mediumAvatar />
                ))
              )}
            </div>
          ) : (
            <UserMultiSelector
              type="all"
              readOnly={isClosed || !isAdmin}
              selected={participants}
              usersToLeaveOut={[assignee]}
              onUserAdded={(user) =>
                addCPAUser({
                  variables: {
                    action: 'PARTICIPANT',
                    userId: user.id,
                    correctiveActionId: parseInt(id),
                  },
                }).then(() => refetch())
              }
              onUserRemoved={(user) =>
                deleteCPAUser({
                  variables: {
                    userId: user.id,
                    correctiveActionId: parseInt(id),
                  },
                }).then(() => refetch())
              }
            />
          )}
          <div className={styles.sectionLine} />
          <Text color="secondary" weight="semiBold" size="sm" noMargin>
            Created
          </Text>
          <SimpleUserCard
            largeAvatar
            user={creator}
            smallText={getLocalTime(dateCreated).format(
              'ddd, MMMM Do YYYY HH:mm',
            )}
          />
          <div className={styles.sectionLine} />
          {!!accepter?.user?.id && (
            <>
              <Text color="secondary" weight="semiBold" size="sm" noMargin>
                Acceptance
              </Text>
              <SimpleUserCard
                smallText={getLocalTime(accepter?.dateOfAction).format(
                  'ddd, MMMM Do YYYY HH:mm',
                )}
                largeAvatar
                user={accepter.user}
              />
              <div className={styles.sectionLine} />
            </>
          )}
          {!!reviewer?.user?.id && (
            <>
              <Text color="secondary" weight="semiBold" size="sm" noMargin>
                Reviewed
              </Text>
              <SimpleUserCard
                smallText={getLocalTime(reviewer?.dateOfAction).format(
                  'ddd, MMMM Do YYYY HH:mm',
                )}
                largeAvatar
                user={reviewer.user}
              />
              <div className={styles.sectionLine} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
