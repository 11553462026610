import { paths } from '../constants/strings';
import { keys } from './translator/translation_keys';
import { t } from 'i18next';

export const actionMap = {
  ACTION_ITEM_ASSIGNED: ({ actionItem, creator, isCorrective }) => ({
    icon: 'task_alt',
    iconColor: 'primary',
    header: t(keys.common.ACTION_ITEMS),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_ASSIGNED_TITLE),
    description: t(keys.notifications.ACTION_ITEM_ASSIGNED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: actionItem.isCorrective
      ? `/cpa/${actionItem.cpaId}`
      : `?actionitem=${actionItem.id}`,
    object: actionItem,
  }),
  ACTION_ITEM_PARTICIPANT: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'primary',
    header: t(keys.common.ACTION_ITEMS),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_PARTICIPANT_TITLE),
    description: t(keys.notifications.ACTION_ITEM_PARTICIPANT_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: actionItem.isCorrective
      ? `/cpa/${actionItem.cpaId}`
      : `?actionitem=${actionItem.id}`,
    object: actionItem,
  }),
  ACTION_ITEM_REMINDER: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'yellow',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_REMINDER_TITLE),
    description: t(keys.notifications.ACTION_ITEM_REMINDER_DESCRIPTION, {
      variable:
        t(keys.notifications[actionItem.lastNotification]) ||
        t(keys.notifications.DUE),
    }),
    url: actionItem.isCorrective
      ? `/cpa/${actionItem.cpaId}`
      : `?actionitem=${actionItem.id}`,
    object: actionItem,
  }),
  ACTION_ITEM_COMMENT: ({ actionItemComment, creator }) => ({
    icon: 'task_alt',
    iconColor: 'primary',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_COMMENT_TITLE, {
      user: creator.firstName,
      actionItemTitle: actionItemComment.actionItem.title,
    }),
    description: actionItemComment.text,
    details: actionItemComment.text,
    url: actionItemComment.actionItem.isCorrective
      ? `/cpa/${actionItemComment.actionItem.cpaId}`
      : `?actionitem=${actionItemComment.actionItem.id}`,
    object: actionItemComment,
  }),
  ACTION_ITEM_COMPLETE: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'green',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_COMPLETE_TITLE),
    description: t(keys.notifications.ACTION_ITEM_COMPLETE_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: actionItem.isCorrective
      ? `/cpa/${actionItem.cpaId}`
      : `?actionitem=${actionItem.id}`,
    object: actionItem,
  }),
  ACTION_ITEM_DUE_DATE: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'yellow',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_DUE_DATE_TITLE),
    description: t(keys.notifications.ACTION_ITEM_DUE_DATE_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: actionItem.isCorrective
      ? `/cpa/${actionItem.cpaId}`
      : `?actionitem=${actionItem.id}`,
    object: actionItem,
  }),
  INSPECTION_COMPLETED: ({ inspection, creator, workspaceId }) => ({
    icon: 'inventory',
    iconColor: inspection.passed ? 'green' : 'red',
    header: t(keys.common.ASSESSMENTS),
    avatar: creator.avatarUrl,
    title: `${inspection.template.title} ${
      inspection.passed ? t(keys.common.PASSED) : t(keys.common.FAILED)
    }`,
    description: t(keys.notifications.ASSESSMENT_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName} ${
        inspection.participants.length > 1
          ? t(keys.notifications.ASSESSMENT_PARTICIPANTS)
          : ''
      }`,
    }),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection.id}`,
    object: inspection,
  }),
  INCIDENT_REPORTED: ({ creator, workspaceId, incident }) => ({
    icon: 'fmd_bad',
    iconColor: 'red',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_REPORTED_TITLE),
    description: t(keys.notifications.INCIDENT_REPORTED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
    object: incident,
  }),
  INCIDENT_DEESCALATED: ({ creator, workspaceId, incident }) => ({
    icon: 'fmd_bad',
    iconColor: 'red',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_DEESCALATED_TITLE),
    description: t(keys.notifications.INCIDENT_DEESCALATED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
    object: incident,
  }),
  INCIDENT_DISMISSED: ({ creator, workspaceId, incident }) => ({
    icon: 'fmd_bad',
    iconColor: 'red',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_DISMISSED_TITLE),
    description: t(keys.notifications.INCIDENT_DISMISSED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
    object: incident,
  }),
  INCIDENT_APPROVED: ({ creator, workspaceId, incident }) => ({
    icon: 'fmd_bad',
    iconColor: 'green',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_APPROVED_TITLE),
    description: t(keys.notifications.INCIDENT_APPROVED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
  }),
  INCIDENT_CLOSED: ({ creator, workspaceId, incident }) => ({
    icon: 'fmd_bad',
    iconColor: 'yellow',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_CLOSED_TITLE),
    description: t(keys.notifications.INCIDENT_CLOSED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
  }),
  EXPENSE_SUBMITTED: ({ creator, expense }) => ({
    icon: 'paid',
    iconColor: 'green',
    header: t(keys.common.EXPENSE),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.EXPENSE_SUBMITTED_TITLE),
    description: t(keys.notifications.EXPENSE_SUBMITTED_DESCRIPTION, {
      variable: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/${paths.expense}/?id=${expense?.id}`,
    object: expense,
  }),
  EXPENSE_DENIED: ({ creator, expense }) => ({
    icon: 'paid',
    iconColor: 'red',
    header: t(keys.common.EXPENSE),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.EXPENSE_DENIED_TITLE),
    description: t(keys.notifications.EXPENSE_DENIED_DESCRIPTION),
    url: `/${paths.expense}/?id=${expense?.id}`,
    object: expense,
  }),
  TIMECARD_DENIED: ({ creator, timecard }) => ({
    icon: 'timer',
    iconColor: 'red',
    header: t(keys.common.TIMECARD),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.TIMECARD_DENIED_TITLE),
    description: t(keys.notifications.TIMECARD_DENIED_DESCRIPTION),
    url: `/${paths.timecard}/?id=${timecard?.id}`,
    object: timecard,
  }),
  FILE_EXPIRY_REMINDER: ({ creator, file, workspaceId }) => ({
    icon: 'folder',
    iconColor: 'yellow',
    header: t(keys.common.FILE),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.FILE_TITLE, { variable: file.name }),
    description: t(keys.notifications.FILE_DESCRIPTION, {
      name: file.name,
      expiry:
        t(keys.notifications[file.lastNotification]) ||
        t(keys.notifications.DUE),
    }),
    url: `/workspace/${workspaceId}/${paths.files}/${
      file.workspaceId ? 'workspace' : 'global'
    }?file=${file.id}&parent=${file.parentId}`,
    object: file,
  }),
  OBSERVATION_SUBMITTED: ({ creator, workspaceId, observation }) => ({
    icon: 'content_paste_search',
    iconColor: 'yellow',
    header: t(keys.common.OBSERVATION),
    avatar: creator?.avatarUrl ?? null,
    title: t(keys.notifications.OBSERVATION_SUBMITTED_TITLE),
    description: t(keys.notifications.OBSERVATION_SUBMITTED_DESCRIPTION),
    url: `/workspace/${workspaceId}/${paths.observation}/${observation?.id}`,
    object: observation,
  }),
  OBSERVATION_REVIEWED: ({ creator, workspaceId, observation }) => ({
    icon: 'content_paste_search',
    iconColor: 'yellow',
    header: t(keys.common.OBSERVATION),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.OBSERVATION_REVIEWED_TITLE),
    description: t(keys.notifications.OBSERVATION_REVIEWED_DESCRIPTION),
    url: `/workspace/${workspaceId}/${paths.observation}/${observation?.id}`,
    object: observation,
  }),
  ASSESSMENT_REGRET: ({ creator, workspaceId, inspection }) => ({
    icon: 'inventory',
    iconColor: 'yellow',
    header: t(keys.common.ASSESSMENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ASSESSMENT_REGRET_TITLE),
    description: t(keys.notifications.ASSESSMENT_REGRET_DESCRIPTION),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection?.id}`,
    object: inspection,
  }),
  ASSET_ASSESSED: ({ inspection, workspaceId, creator }) => ({
    icon: 'local_shipping',
    iconColor: 'yellow',
    header: t(keys.common.ASSETS),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ASSET_ASSESSED_TITLE, {
      asset: `${inspection.vehicle?.make} ${inspection.vehicle?.model} ${inspection.vehicle?.unitNumber}`,
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    description: t(keys.notifications.ASSET_ASSESSED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
      asset: `${inspection.vehicle?.make} ${inspection.vehicle?.model} ${inspection.vehicle?.unitNumber}`,
    }),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection?.id}`,
    object: inspection,
  }),
  ASSET_UPDATED: ({ vehicle, creator, workspaceId }) => ({
    icon: 'local_shipping',
    iconColor: 'yellow',
    header: t(keys.common.ASSETS),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ASSET_UPDATED_TITLE, {
      asset: `${vehicle?.make} ${vehicle?.model} ${vehicle?.unitNumber}`,
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    description: t(keys.notifications.ASSET_UPDATED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.asset}/${vehicle?.id}`,
    object: vehicle,
  }),
  TEMPLATE_REVISED: ({ inspectionTemplate, creator, workspaceId }) => ({
    icon: 'inventory',
    iconColor: 'yellow',
    header: t(keys.common.TEMPLATES),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.TEMPLATE_REVISED_TITLE, {
      template: `${inspectionTemplate.title}`,
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    description: t(keys.notifications.TEMPLATE_REVISED_DESCRIPTION, {
      template: `${inspectionTemplate.title}`,
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.templates}/${inspectionTemplate?.id}`,
    object: inspectionTemplate,
  }),
  TEMPLATE_USED: ({ inspection, creator, workspaceId }) => ({
    icon: 'inventory',
    iconColor: 'yellow',
    header: t(keys.common.TEMPLATES),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.TEMPLATE_USED_TITLE, {
      template: `${inspection.template.title}`,
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    description: t(keys.notifications.TEMPLATE_USED_DESCRIPTION, {
      template: `${inspection.template.title}`,
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection?.id}`,
    object: inspection,
  }),
  TEMPLATE_SUBMITTED: ({ inspection, creator, workspaceId }) => ({
    icon: 'inventory',
    iconColor: 'yellow',
    header: t(keys.common.TEMPLATES),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.TEMPLATE_SUBMITTED_TITLE, {
      template: `${inspection.template.title}`,
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    description: t(keys.notifications.TEMPLATE_SUBMITTED_DESCRIPTION, {
      template: `${inspection.template.title}`,
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection?.id}`,
  }),
  FILE_DELETED: ({ file, creator }) => ({
    icon: 'folder',
    iconColor: 'red',
    header: t(keys.common.FILE),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.FILE_DELETED_TITLE, { variable: file.name }),
    description: t(keys.notifications.FILE_DELETED_DESCRIPTION),
    url: ``,
    object: file,
  }),
  FILE_REVISION: ({ file, creator, workspaceId }) => ({
    icon: 'folder',
    iconColor: 'yellow',
    header: t(keys.common.FILE),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.FILE_REVISION_TITLE, { variable: file.name }),
    description: t(keys.notifications.FILE_REVISION_DESCRIPTION),
    url: `/workspace/${workspaceId}/${paths.files}/${
      file.workspaceId ? 'workspace' : 'global'
    }?file=${file.id}&parent=${file.parentId}`,
    object: file,
  }),
  ASSESSMENT_CHANGE_REQUEST: ({ inspection, creator, workspaceId }) => ({
    icon: 'inventory',
    iconColor: 'yellow',
    header: t(keys.common.ASSESSMENTS),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ASSESSMENT_CHANGE_REQUEST_TITLE, {
      title: inspection?.template?.title,
    }),
    description: t(keys.notifications.ASSESSMENT_CHANGE_REQUEST_DESCRIPTION, {
      creator: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection.id}`,
    object: inspection,
  }),
  ASSESSMENT_REVIEW: ({ inspection, creator, workspaceId }) => ({
    icon: 'inventory',
    iconColor: 'yellow',
    header: t(keys.common.ASSESSMENTS),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ASSESSMENT_REVIEW_TITLE, {
      title: inspection?.template?.title,
    }),
    description: t(keys.notifications.ASSESSMENT_REVIEW_DESCRIPTION, {
      creator: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection.id}`,
    object: inspection,
  }),
  INCIDENT_COMMENT_REQUIRED: ({ incident, creator, workspaceId }) => ({
    icon: 'fmd_bad',
    iconColor: 'yellow',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_COMMENT_TITLE),
    description: t(keys.notifications.INCIDENT_COMMENT_DESCRIPTION),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident.id}`,
    object: incident,
  }),
  EXTENSION_REQUESTED: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'yellow',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: 'Extension Requested',
    description: `Extension requested for ${actionItem.title} on ${actionItem.correctiveAction.externalId}`,
    url: `/cpa/${actionItem.cpaId}`,
    object: actionItem,
  }),
  EXTENSION_GRANTED: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'yellow',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: 'Extension Granted',
    description: `Extension granted for ${actionItem.title} on ${actionItem.correctiveAction.externalId}`,
    url: `/cpa/${actionItem.cpaId}`,
    object: actionItem,
  }),
};
