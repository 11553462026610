import { useModal } from '../../../providers/ModalProvider';
import Modal from '../Modal';
import { modals } from '../../../providers/modals';
import { Text } from '../../typography';
import { Form } from 'react-bootstrap';
import { DropdownText } from '../../dropdowns/DropdownText';
import { incidentTypes } from '../../../utilities/incidents';
import UserMultiSelector from '../../UserMultiSelector';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import styles from './CreateIncidentModal.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import Icon from '../../Icon';
import { useIncidents } from '../../../hooks/offline-hooks/incidentHook';
import { showToast } from '../../../graphql/cache/modal';
import QuickFileDropzone from '../../image_uploads/QuickFileDropzone';
import { useState, useEffect } from 'react';
import findFileIcon, { imageExtensions } from '../../../utilities/files.js';
import ImageWrapper from '../../offline-wrapper-functions/image-wrapper.js';
import { openFileViewer } from '../../../graphql/cache/modal';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { getRoute, paths } from '../../../constants/strings.js';
import { useNavigate } from 'react-router-dom';
import { IncidentReportAviation } from '../../../pages/incidents/IncidentReportAviation.jsx';

const addImageMutation = loader(
  '../../../pages/incidents/IncidentReport.addImage.graphql',
);

export default function CreateIncidentModal() {
  const { updateModal, closeModal, modalState } = useModal();
  const [loading, setLoading] = useState(false);
  const onHide = () => {
    closeModal({ modalName: modals.createIncident });
    setShowNext(false);
  };
  const [showNext, setShowNext] = useState(false);
  const { workspaceId, workspace } = useWorkspace();
  const {
    id,
    show,
    modalTitle,
    type,
    subtype,
    participants,
    observation,
    description,
    dateOfIncident,
    location,
    images = [],
    navigate,
  } = modalState.createIncidentModal;
  const isAviation = type === 'Aviation';
  const { t } = useTranslation();
  const { addIncident } = useIncidents();
  const update = (variables) => {
    updateModal({
      modalName: modals.createIncident,
      variables,
    });
  };
  const incident = modalState.createIncidentModal;
  const [addImage] = useMutation(addImageMutation);
  const navigation = useNavigate();

  useEffect(() => {
    if (isAviation && !id) {
      setShowNext(true);
    } else setShowNext(false);
  }, [id, isAviation, type]);

  function onSubmit() {
    addIncident({
      variables: {
        ...incident,
        participants: participants?.map((user) => user?.id) || null,
        status: 'INITIATED',
        workspaceId,
        observationId: observation?.id ?? null,
      },
    }).then(({ data: { addIncident } }) => {
      update({ id: addIncident.id });
      if (images?.length) {
        for (let image of images) {
          addImage({
            variables: {
              incidentId: addIncident.id,
              description: 'Initial Report',
              type: 'INITIAL',
              imageUrl: image.url,
            },
          });
        }
      }
      if (!showNext) {
        showToast({
          title: t(keys.incidents.SUBMITTED),
          message: t(keys.incidents.SUBMITTED_MESSAGE),
        });
        onHide();
      }
    });
  }

  return (
    <Modal
      open={show || false}
      title={t(keys.incidents.REPORT_INCIDENT) || modalTitle}
      submitDisabled={!type || !subtype || !description || !dateOfIncident}
      onClose={onHide}
      disableCloseOnSubmit={true}
      submitText={isAviation && !incident.id ? t(keys.action.NEXT) : undefined}
      titleImage={
        <Icon style={{ fontSize: '2rem' }} color="red">
          fmd_bad
        </Icon>
      }
      onSubmit={() => {
        if (!id) {
          onSubmit();
          setShowNext(false);
        }
        if (isAviation && id) {
          onHide();
          showToast({
            title: 'Aviation Details Submitted',
            message:
              'Thanks! Your Incident has been updated. You will be contacted when it has been reviewed.',
          });
          if (!!navigate) {
            navigation(getRoute(workspaceId, paths.incident, addIncident.id));
          }
        }
      }}
      className={styles.maxWidth}
    >
      {isAviation && id ? (
        <IncidentReportAviation
          incident={incident}
          readOnly={false}
          initialReport={true}
        />
      ) : (
        <>
          <br />
          <Text noMargin weight="semiBold">
            {`${t(keys.incidents.TYPE)}`}
            <span className={styles.red}>*</span>
          </Text>
          <div className={styles.spacer} />
          <DropdownText
            selected={type}
            highlight
            required={true}
            onChange={(newType) => {
              update({ type: newType, subtype: null });
            }}
            items={Object.keys(incidentTypes)}
            placeholder={`${t(keys.incidents.TYPE)}`}
          />
          <Text noMargin weight="semiBold">
            {`${t(keys.incidents.SUBTYPE)}`}
            <span className={styles.red}>*</span>
          </Text>
          <div className={styles.spacer} />
          <DropdownText
            highlight
            required={true}
            selected={subtype}
            onChange={(newSubtype) => update({ subtype: newSubtype })}
            items={incidentTypes[type]}
            placeholder={`${t(keys.incidents.SUBTYPE)}`}
            disabled={!type}
          />
          <div className={styles.field}>
            <Text noMargin weight="semiBold">
              {`${t(keys.incidents.DATE_OF_INCIDENT)}`}
              <span className={styles.red}>*</span>
            </Text>
            <input
              className={styles.date}
              type="datetime-local"
              max={new Date().toISOString().slice(0, 16)}
              value={dateOfIncident || ''}
              onChange={(e) => {
                const newValue = e.target.value;
                update({ dateOfIncident: newValue });
              }}
            />
          </div>

          <Text noMargin weight="semiBold">
            {`${t(keys.common.LOCATION)}`}
            <span className={styles.red}>*</span>
          </Text>
          <div className={styles.spacer} />
          {!!workspace?.locations ? (
            <DropdownText
              placeholder={'Location'}
              selected={location}
              items={workspace.locations.split('|')}
              onChange={(location) => update({ location })}
              onRemove={() => update({ location: null })}
            />
          ) : (
            <div>
              <Form.Control
                as="textarea"
                rows={2}
                value={location}
                onChange={(e) => update({ location: e.target.value })}
              />
              <div className={styles.spacer} />
            </div>
          )}
          <div className={styles.field}>
            <Text noMargin weight="semiBold">
              {`${t(keys.common.DESCRIPTION)}`}
              <span className={styles.red}>*</span>
            </Text>
            <Form.Control
              as="textarea"
              rows={4}
              value={description}
              onChange={(e) => update({ description: e.target.value })}
            />
          </div>

          <div className={styles.field}>
            <Text weight="semiBold" noMargin>
              {t(keys.common.PARTICIPANTS)}
            </Text>
            <UserMultiSelector
              selected={participants}
              type="users"
              onUserAdded={(user) => {
                update({ participants: [...participants, user] });
              }}
              onUserRemoved={(user) => {
                update({
                  participants: participants?.filter((u) => u.id !== user.id),
                });
              }}
            />
            <Text weight="semiBold" noMargin>
              {t(keys.common.ATTACHMENTS)}
            </Text>
            <QuickFileDropzone
              onSubmit={(image) => update({ images: [...images, image] })}
              loading={loading}
              setLoading={setLoading}
              icon={!!images.length}
              iconText={t(keys.action.ADD, { variable: t(keys.common.FILE) })}
            />
            <div className={styles.images}>
              {images.map(({ extension, url }, index) => {
                const isImage = imageExtensions.includes(extension);
                const src = isImage ? url : findFileIcon(extension);
                const file = {
                  fileType: extension,
                  url,
                  imageUrl: url,
                  downloadAllowed: true,
                };
                return (
                  <div key={`image-${url}-${index}}`}>
                    <ImageWrapper
                      key={extension}
                      alt={`observation-${index}`}
                      style={{ height: '8rem', width: 'auto' }}
                      image={src}
                      className={isImage ? styles.image : styles.file}
                      onClick={() => openFileViewer(file)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <Text textAlign="center" color="secondary" weight="semiBold">
            {workspace?.incidentEmergencyMessage ?? ''}
          </Text>
        </>
      )}
    </Modal>
  );
}
