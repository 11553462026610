import { assessmentTypes } from '../constants/strings';
import { customColors } from '../utilities';

export const modals = {
  createAssessment: 'createAssessmentModal',
  createTemplate: 'createTemplateModal',
  createIncident: 'createIncidentModal',
  createWorkspace: 'createWorkspaceModal',
  noWorkspace: 'noWorkspaceModal',
  fileUpload: 'fileUploadModal',
  recordMeeting: 'recordMeetingModal',
  announcement: 'announcementModal',
  quickAction: 'quickActionModal',
  observation: 'observationModal',
  task: 'taskModal',
  role: 'roleModal',
  confirmation: 'confirmationModal',
  CPA: 'CPAModal',
  audit: 'createAuditModal',
};

const taskAssessmentTypes = Object.values(assessmentTypes).filter(
  ({ key }) => key !== 'INCIDENT' && key !== 'OBSERVATION',
);

export const modalDefaultState = {
  createAssessmentModal: {
    show: false,
    types: Object.values(assessmentTypes),
    incidentId: null,
    timecardId: null,
    asset: null,
    modalTitle: null,
    template: null,
    participants: null,
    type: assessmentTypes.fleet,
    dataWorkspaceId: null,
    urlParams: [
      { param: 'modalName', value: modals.createAssessment },
      { param: 'type', paramTarget: 'key', value: null },
      { param: 'template', paramTarget: 'id', value: null },
      { param: 'asset', paramTarget: 'id', value: null },
    ],
  },
  createTemplateModal: {
    show: false,
    types: Object.values(assessmentTypes),
    title: '',
    description: '',
    reviewRequired: false,
    analysisRequested: false,
    allWorkspaces: false,
    modalTitle: null,
    type: assessmentTypes.fleet,
    options: { allWorkspaces: true, secondSight: true, approvalRequired: true },
  },
  createIncidentModal: {
    show: false,
    modalTitle: 'Report Incident',
    type: undefined,
    subtype: undefined,
    participants: undefined,
    observation: undefined,
    description: undefined,
    dateOfIncident: undefined,
    location: undefined,
    images: [],
    id: null,
    navigate: false,
  },
  createWorkspaceModal: {
    show: false,
    title: '',
    description: '',
  },
  noWorkspaceModal: {
    show: false,
    modalTitle: 'Select Workspace',
    allowClose: true,
  },
  fileUploadModal: {
    show: false,
    modalTitle: null,
    status: null,
    type: null,
    asset: null,
    expires: null,
    user: null,
    downloadAllowed: true,
    name: null,
    onLoading: () => {},
    fileToUpload: null,
    simple: false,
    allWorkspaces: false,
    parentId: null,
    size: null,
    onSubmit: () => {},
    assessment: false,
    previousAdditional: null,
    previousValue: null,
    fromAsset: false,
    accept: '*',
  },
  recordMeetingModal: {
    workspaceId: null,
    show: false,
    participants: null,
    internalType: 'RECORDED_MEETING',
    safetyTopic: '',
    description: '',
    externalParticipants: '',
    location: '',
    attachment: null,
    refetch: () => {},
    onView: null,
    regrets: [],
    signatures: [],
  },
  announcementModal: {
    show: false,
    announcement: null,
    status: 'READ',
    id: null,
  },
  quickActionModal: {
    color: customColors.BLUE,
    name: null,
    icon: null,
    type: assessmentTypes.fleet,
    template: null,
    asset: null,
    workspace: null,
    show: false,
    refetch: () => {},
    urlParams: [
      { param: 'modalName', value: modals.task },
      { param: 'modalName', value: modals.role },
      { param: 'type', paramTarget: 'key', value: null },
      { param: 'template', paramTarget: 'id', value: null },
      { param: 'workspace', paramTarget: 'id', value: null },
    ],
  },
  observationModal: {
    show: false,
    dateTime: undefined,
    location: null,
    participants: undefined,
    negative: false,
    description: undefined,
    anonymous: false,
    workspaceId: undefined,
    images: [],
    refetch: () => {},
    templateId: undefined,
    questions: [],
    preview: false,
    private: false,
    incidentId: null,
  },
  taskModal: {
    show: false,
    title: '',
    interval: 'ONE_TIME',
    endDate: '',
    types: taskAssessmentTypes,
    type: assessmentTypes.fleet,
    taskType: 'OBSERVATION',
    target: 5,
    targetInterval: 1,
    targetPeriod: 'WEEK',
    template: null,
    modalTitle: null,
    assignees: [],
    taskWorkspace: null,
    readOnly: false,
    urlParams: [
      { param: 'modalName', value: modals.task },
      { param: 'type', paramTarget: 'key', value: null },
      { param: 'template', paramTarget: 'id', value: null },
      { param: 'workspace', paramTarget: 'id', value: null },
      { param: 'taskType', value: null },
      { param: 'target', value: null },
      { param: 'targetInterval', value: null },
      { param: 'targetPeriod', value: null },
    ],
  },
  roleModal: {
    show: false,
    roleTitle: '',
    roleDescription: '',
    requirementTitle: '',
    requirementDescription: '',
    modalTitle: null,
    readOnly: false,
    urlParams: [{ param: 'modalName', value: modals.role }],
  },
  confirmationModal: {
    show: false,
    title: undefined,
    description: undefined,
    highlight: false,
    wordsToHighlight: [],
    variant: undefined,
    buttonText: undefined,
    onHide: () => {},
    onSubmit: () => {},
    icon: 'warning',
    doubleConfirm: false,
    confirmText: '',
    textInput: false,
    textInputTitle: '',
    cancelText: undefined,
    onCancel: undefined,
  },
  CPAModal: {
    show: false,
    onSubmit: () => {},
  },
  createAuditModal: {
    show: false,
    subject: null,
    customer: null,
    dateCreated: null,
    auditType: null,
    asset: null,
    template: null,
    urlParams: [
      { param: 'modalName', value: modals.createAssessment },
      { param: 'type', paramTarget: 'key', value: null },
      { param: 'template', paramTarget: 'id', value: null },
      { param: 'asset', paramTarget: 'id', value: null },
    ],
  },
};
